import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { LinearChannel } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

import { getVideoImg } from '@/Utils/VideoImage';

import styles from './styles.module.scss';

interface ChannelColumnProps {
  channels: Omit<LinearChannel, 'slots'>[];
  onChannelIconClick?: (channel: Omit<LinearChannel, 'slots'>) => void;
}

const ChannelColumn: FC<ChannelColumnProps> = ({ channels, onChannelIconClick }) => {
  return (
    <div className={styles.channels}>
      <div className={styles.title}>
        <span className={styles.text}>Channels</span>
      </div>
      {channels.map(channel => {
        const { id, title, tileImage, number } = channel;
        return (
          <Link
            to={`/watch-tv/${id}?playerReferrer=TV Guide`}
            className={styles.channel}
            key={id}
            onClick={() => onChannelIconClick?.(channel)}
          >
            <span className={styles.channelImage}>
              {tileImage?.uri && (
                <img
                  alt={title}
                  src={getVideoImg(ImageAssetType.ContentTileVertical, tileImage.uri)}
                />
              )}
            </span>
            <span className={styles.channelNumber}>{number.toString().padStart(3, '0')}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default ChannelColumn;
