import React, { useMemo } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';
import { selectors } from '@/Store';

interface Props {
  children?: any;
  authorizationKey?: string;
}

const USER_PROFILE_ID_KEY = 'https://idm.sky.co.nz/profile_id';
const FeatureToggleProvider: React.FC<Props> = ({ children, authorizationKey }) => {
  const user = useSelector(selectors.auth.user);
  const splitioConfig = useMemo(
    () => ({
      core: {
        authorizationKey: authorizationKey || '',
        key:
          user?.[USER_PROFILE_ID_KEY] ||
          `anonymous|${
            // analytics.user may become 'not a function' when the page refreshes after logout
            window.analytics?.user instanceof Function ? window.analytics?.user().anonymousId() : ''
          }`,
      },
    }),
    [user, authorizationKey],
  );

  return <SplitFactory config={splitioConfig}>{children}</SplitFactory>;
};

export default FeatureToggleProvider;
