/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AccountLocked {
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface AccountNotLinkedOrSubscriptionUpgradeEncountered {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * The channel in which the video content is playing
	 */
	channel?: any[]
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type?: string
	/**
	 * The name (or names) of current user subscription package
	 */
	subscription?: any[]
	/**
	 * The name (or names) of subscription required to play a content
	 */
	subscription_required?: any[]
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ActiveDeviceNameUpdateCancelled {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ActiveDeviceNameUpdated {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ActiveDeviceRemovalCancelled {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ActiveDeviceRemoved {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface AirPlayConnected {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface AirPlayDisconnected {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ApplicationAddedToFavourites {
	/**
	 * Name of installed app
	 */
	name?: Record<string, any>
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ApplicationInstalled {
	/**
	 * Build number of the installed app
	 */
	build?: string
	/**
	 * Version installed
	 */
	version?: string
}
export interface ApplicationManagerOpened {
	/**
	 * screen or page card is being viewed on
	 */
	view_name: string
}
export interface ApplicationOpened {
	/**
	 * Build number of the installed app
	 */
	build?: string
	/**
	 * If application transitioned from “Background” to “Inactive” state prior to foregrounding (as opposed to from “Not Running” state). Automatically collected on iOS only.
	 */
	from_background?: boolean
	referring_application?: any | null
	/**
	 * The value of UIApplicationLaunchOptionsURLKey from launchOptions (auto-collected on iOS only)
	 */
	url?: string
	/**
	 * Version installed
	 */
	version?: string
}
export interface ApplicationRemovedFromFavourites {
	/**
	 * Position in rail (from left) or logical number (channel)
	 */
	content_position?: number
	/**
	 * Name of installed app
	 */
	name?: Record<string, any>
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ApplicationUpdated {
	/**
	 * Build number of the installed app
	 */
	build?: string
	/**
	 * Previously recorded build
	 */
	previous_build?: string
	/**
	 * Previously recorded version
	 */
	previous_version?: string
	/**
	 * Version installed
	 */
	version?: string
}
export interface BrowseFilterSelected {
	/**
	 * Browse collection name
	 */
	collection_name: string
	/**
	 * Name of filter to narrow down or sort browsed content
	 */
	filter_name: string
	/**
	 * Value of filter to narrow down or sort browsed content
	 */
	filter_value: string
}
export interface BrowseGenreSelected {
	/**
	 * Browse collection name
	 */
	collection_name: string
	/**
	 * The genre of the content e.g. "Comedy", "Action", "Adventure".
	 */
	genre: string
}
export interface ChromecastConnected {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ChromecastDisconnected {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface ConcurrencyLimitReached {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
}
export interface ContentAddedToMyList {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface MenuItemClicked {
	/**
	 * The menu item name
	 */
	menu_item: string
}
export interface ContentCardClicked {
	/**
	 * Rail title, channel group or other
	 */
	category_name: string
	/**
	 * Unique IDs of remaining content within same group/category
	 */
	contains?: any[]
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * Position in rail (from left) or logical number (channel)
	 */
	content_position?: number
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * "Show" or "Movie", or other context-dependent values
	 */
	content_type?: string
	/**
	 * Landscape, Portrait, Grid, Drawer
	 */
	style?: string
	/**
	 * screen or page card is being viewed on
	 */
	view_name: string
}
export interface SkipIntroSelected {
	/**
	 * Meadia Asset Id of video where SkipIntro was clicked
	 */
	asset_id: string
	/**
	 * Title of the video
	 */
	title: string
}
export interface SubtitleLanguageChanged {
	/**
	 * Channel ID
	 */
	channel_id?: string
	/**
	 * Video Slot
	 */
	slot_id?: string
	/**
	 * Subtitle language  selected
	 */
	subtitle: string
	/**
	 * Title of the video
	 */
	title: string
}
export interface WatchCreditSelected {
	/**
	 * Meadia Asset Id of video where WatchCredits was clicked
	 */
	asset_id: string
	/**
	 * Title of the video
	 */
	title: string
}
export interface NextEpisodeSelected {
	/**
	 * Meadia Asset Id of video where NextEpisode was clicked
	 */
	asset_id: string
	/**
	 * Title of the video
	 */
	title: string
}
export interface ContentDownloadCompleted {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type?: string
	/**
	 * The episode number if applicable.
	 */
	episode?: number
	/**
	 * Size of video asset file in bytes
	 */
	file_size?: number
	/**
	 * The genre of the content, ie. ‘comedy’, ‘action’.
	 */
	genre?: any[]
	/**
	 * True if content was downloaded again after expiration
	 */
	redownload?: boolean
	/**
	 * The season number if applicable.
	 */
	season?: number
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ContentDownloadRemoved {
	/**
	 * Boolean indicator for content deletion
	 */
	all?: boolean
}
export interface ContentDrawerOpened {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ContentRemovedFromMyList {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface DeviceRegistered {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface DeviceUpgradeDismissed {
	/**
	 * Generic or user updated device name
	 */
	device_name?: any[]
	/**
	 * Unique ID of SKY decoder (smartcard or serial number)
	 */
	settopbox_id?: any[]
}
export interface DeviceUpgradeEncountered {
	/**
	 * Generic or user updated device name
	 */
	device_name?: any[]
	/**
	 * Unique ID of SKY decoder (smartcard or serial number)
	 */
	settopbox_id?: any[]
}
export interface DeviceUpgradePursued {
	/**
	 * Generic or user updated device name
	 */
	device_name?: any[]
	/**
	 * Unique ID of SKY decoder (smartcard or serial number)
	 */
	settopbox_id?: any[]
}
export interface EmailVerified {
	/**
	 * The user's email address (master billing account)
	 */
	email: string
}
export interface NotificationDismissed {
	/**
	 * Notification body metadata displayed to user
	 */
	notification_text?: string
	/**
	 * Notification title metadata displayed to user
	 */
	notification_title: string
	/**
	 * Internal type (category) of notification displayed to user
	 */
	notification_type: string
}
export interface NotificationSeen {
	/**
	 * Notification body metadata displayed to user
	 */
	notification_text?: string
	/**
	 * Notification title metadata displayed to user
	 */
	notification_title: string
	/**
	 * Internal type (category) of notification displayed to user
	 */
	notification_type: string
}
export interface OnScreenGuideProgramDetailsViewed {
	/**
	 * Linear channel name (or controlling channel name for VOD) in which the video content is playing.
	 */
	channel: string
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type: string
	/**
	 * True if linear programme is or will be a live (real-time) broadcast
	 */
	livestream: boolean
	/**
	 * True if linear programme is on air at the moment
	 */
	on_air: boolean
}
export interface PageViewed {
	name?: string
	path?: string
	referrer?: string
	search?: string
	title?: string
	url?: string
}
export interface ParentalPinClassificationChanged {
	/**
	 * Content restriction classification code
	 */
	classification_code?: string
}
export interface ParentalPinControlChanged {
	/**
	 * State of functionality being able to be used or not
	 */
	availability?: string
}
export interface ParentalPinCreated {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ParentalPinEntered {
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ParentalPinRequired {
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface ParentalPinResetRequested {
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface PermittedDeviceLimitReached {
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
}
export interface PromotionalChannelSelected {
	/**
	 * Name of installed app
	 */
	name?: Record<string, any>
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface RemoteRecordAccountLinkDismissed {
	/**
	 * Generic or user updated device name
	 */
	device_name?: any[]
	/**
	 * Unique ID of SKY decoder (smartcard or serial number)
	 */
	settopbox_id?: any[]
}
export interface RemoteRecordDismissed {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * The channel in which the video content is playing
	 */
	channel?: string
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type?: string
	/**
	 * The episode number if applicable.
	 */
	episode?: number
	/**
	 * The genre of the content, ie. ‘comedy’, ‘action’.
	 */
	genre?: any[]
	/**
	 * True if linear programme is or will be a live (real-time) broadcast
	 */
	livestream?: boolean
	/**
	 * The season number if applicable.
	 */
	season?: number
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface RemoteRecordScheduled {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * The channel in which the video content is playing
	 */
	channel?: string
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type?: string
	/**
	 * Number of days ahead from today for selected weekday
	 */
	days_from_today?: number
	/**
	 * Unique device identifier used to consume content
	 */
	device_id?: string
	/**
	 * Generic or user updated device name
	 */
	device_name?: string
	/**
	 * The episode number if applicable.
	 */
	episode?: number
	/**
	 * The genre of the content, ie. ‘comedy’, ‘action’.
	 */
	genre?: any[]
	/**
	 * True if linear programme is or will be a live (real-time) broadcast
	 */
	livestream?: boolean
	/**
	 * Type of remote record - episode or series
	 */
	record_type?: string
	/**
	 * The season number if applicable.
	 */
	season?: number
	/**
	 * Unique ID of SKY decoder (smartcard or serial number)
	 */
	settopbox_id?: string
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
	/**
	 * TV guide selected weekday name
	 */
	weekday_name?: string
}
export interface SearchNoResultReturned {
	/**
	 * query that generate search results
	 */
	query?: string
}
export interface ResultsSetItem {
	/**
	 * Asset Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface SearchResultReturned {
	/**
	 * query that generate search results
	 */
	query?: string
	/**
	 * An array of all query matching results (title + asset ID)
	 */
	results_set?: ResultsSetItem[]
}
export interface SignedIn {
	/**
	 * The user's email address (master billing account)
	 */
	email: string
}
export interface SignedOut {
	/**
	 * The user's email address (master billing account)
	 */
	email: string
}
export interface ProfileSelected {
	/**
	 * The user's profile name
	 */
	profile_name: string
}
export interface ProfileCreated {
	/**
	 * The user's profile name
	 */
	profile_name: string
}
export interface ProfileDeleted {
	/**
	 * The user's profile name
	 */
	profile_name: string
}
export interface ProfileNameChanged {
	/**
	 * The user's profile name
	 */
	profile_name: string
}
export interface SignedUp {
	/**
	 * The user's email address (master billing account)
	 */
	email: string
	/**
	 * The user's profile name
	 */
	profile_name: string
	/**
	 * The user's first registration date and time
	 */
	registration_date: string
}
export interface SpotifyAccountLinkInitiated {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface SpotifyAccountLinked {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface SpotifySoundtrackAddedToLibrary {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface SpotifySoundtrackPaused {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * Spotify track ID for played content
	 */
	track_id?: string
	/**
	 * Spotify song title for played content
	 */
	track_title?: string
}
export interface SpotifySoundtrackPlayed {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * Spotify track ID for played content
	 */
	track_id?: string
	/**
	 * Spotify song title for played content
	 */
	track_title?: string
}
export interface SpotifySoundtrackRemovedFromLibrary {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface SubscriptionUpgradeEncountered {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
	/**
	 * The channel in which the video content is playing
	 */
	channel?: any[]
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type?: string
	/**
	 * The name of current user subscription package
	 */
	subscription?: any[]
	/**
	 * The name of subscription required to play a content
	 */
	subscription_required?: any[]
	/**
	 * screen or page card is being viewed on
	 */
	view_name?: string
}
export interface SubscriptionUpgradeMessageDismissed {
	/**
	 * The name of current user subscription package
	 */
	subscription?: any[]
	/**
	 * The name of subscription required to play a content
	 */
	subscription_required?: any[]
}
export interface SubscriptionUpgradePursued {
	/**
	 * The name of current user subscription package
	 */
	subscription?: any[]
	/**
	 * The name of subscription required to play a content
	 */
	subscription_required?: any[]
}
export interface TvGuideChannelGroupSelected {
	/**
	 * Rail title, channel group or other
	 */
	category_name?: string
}
export interface TvGuideMoreEpisodesSelected {
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title?: string
}
export interface TvGuideProgramDetailsViewed {
	/**
	 * Linear channel name (or controlling channel name for VOD) in which the video content is playing.
	 */
	channel: string
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id: string
	/**
	 * The name of the show, movie, collection
	 */
	content_title: string
	/**
	 * hero, program, channel, collection, listing, etc.
	 */
	content_type: string
	/**
	 * True if a linear programme is or will be a live (real-time) broadcast
	 */
	livestream: boolean
	/**
	 * True if linear programme is on air at the moment
	 */
	on_air: boolean
}
export interface TvGuideWeekdaySelected {
	/**
	 * Number of days ahead from today for selected weekday
	 */
	days_from_today?: number
	/**
	 * How weekday is selected e.g. scroll or select
	 */
	method?: string
	/**
	 * TV guide selected weekday name
	 */
	weekday_name?: string
}
export interface VideoContentStarted {
	/**
	 * Linear channel name (or controlling channel name for VOD) in which the video content is playing.
	 */
	channel: any[]
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * Total duration of the playback (in seconds).
	 */
	duration: number
	/**
	 * The episode number if applicable.
	 */
	episode?: number
	/**
	 * The genre of the content e.g. "Comedy", "Action", "Adventure".
	 */
	genre?: any[]
	/**
	 * True if linear programme is or will be a live (real-time) broadcast
	 */
	livestream?: boolean
	/**
	 * Indication whether user is streaming via mobile network, using wireless connection or is not connected to the Internet at all (offline mode)
	 */
	network?: string
	/**
	 * Page or section the playback was initiated from.
	 */
	origin: string
	/**
	 * Video player platform name e.g. "Web", "Mobile", "Tablet", "Chromecast", "AirPlay" or "TV".
	 */
	platform: string
	/**
	 * Indication whether content is a linear stream or VOD.
	 */
	playback: string
	/**
	 * The current index position in seconds of the playhead.
	 */
	position: number
	/**
	 * The quality of the video either based on user settings or maximum bitrate for given network connection type
	 */
	quality?: string
	/**
	 * The season number if applicable.
	 */
	season?: number
	/**
	 * Brand-level or movie title of the video content.
	 */
	title: string
	/**
	 * Type of content e.g. "Movie", "Show".
	 */
	type?: string
}
export interface VideoContentStopped {
	/**
	 * Linear channel name (or controlling channel name for VOD) in which the video content is playing.
	 */
	channel: any[]
	/**
	 * Id of content that was clicked (Skylark)
	 */
	content_id?: string
	/**
	 * Total duration of the playback (in seconds).
	 */
	duration: number
	/**
	 * The episode number if applicable.
	 */
	episode?: number
	/**
	 * The genre of the content e.g. "Comedy", "Action", "Adventure".
	 */
	genre?: any[]
	/**
	 * True if linear programme is or will be a live (real-time) broadcast
	 */
	livestream?: boolean
	/**
	 * Indication whether user is streaming via mobile network, using wireless connection or is not connected to the Internet at all (offline mode)
	 */
	network?: string
	/**
	 * Page or section the playback was initiated from.
	 */
	origin: string
	/**
	 * Video player platform name e.g. "Web", "Mobile", "Tablet", "Chromecast", "AirPlay" or "TV".
	 */
	platform: string
	/**
	 * Indication whether content is a linear stream or VOD.
	 */
	playback: string
	/**
	 * The current index position in seconds of the playhead.
	 */
	position: number
	/**
	 * The quality of the video either based on user settings or maximum bitrate for given network connection type
	 */
	quality?: string
	/**
	 * The season number if applicable.
	 */
	season?: number
	/**
	 * Brand-level or movie title of the video content.
	 */
	title: string
	/**
	 * Type of content e.g. "Movie", "Show".
	 */
	type?: string
}
export interface VideoQualitySettingChanged {
	/**
	 * Video quality setting
	 */
	setting: string
}
export interface WirelessDownloadSettingChanged {
	/**
	 * State of functionality being able to be used or not
	 */
	availability?: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.1',
			},
		},
	}
}

/**
 * @typedef AccountLocked
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef AccountNotLinkedOrSubscriptionUpgradeEncountered
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {any[]} [channel] - The channel in which the video content is playing
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - hero, program, channel, collection, listing, etc.
 * @property {any[]} [subscription] - The name (or names) of current user subscription package
 * @property {any[]} [subscription_required] - The name (or names) of subscription required to play a content
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ActiveDeviceNameUpdateCancelled
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ActiveDeviceNameUpdated
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ActiveDeviceRemovalCancelled
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ActiveDeviceRemoved
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef AirPlayConnected
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef AirPlayDisconnected
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ApplicationAddedToFavourites
 * @property {Record<string, any>} [name] - Name of installed app
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ApplicationInstalled
 * @property {string} [build] - Build number of the installed app
 * @property {string} [version] - Version installed
 */
/**
 * @typedef ApplicationManagerOpened
 * @property {string} view_name - screen or page card is being viewed on
 */
/**
 * @typedef ApplicationOpened
 * @property {string} [build] - Build number of the installed app
 * @property {boolean} [from_background] - If application transitioned from “Background” to “Inactive” state prior to foregrounding (as opposed to from “Not Running” state). Automatically collected on iOS only.
 * @property {any | null} [referring_application] -
 * @property {string} [url] - The value of UIApplicationLaunchOptionsURLKey from launchOptions (auto-collected on iOS only)
 * @property {string} [version] - Version installed
 */
/**
 * @typedef ApplicationRemovedFromFavourites
 * @property {number} [content_position] - Position in rail (from left) or logical number (channel)
 * @property {Record<string, any>} [name] - Name of installed app
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ApplicationUpdated
 * @property {string} [build] - Build number of the installed app
 * @property {string} [previous_build] - Previously recorded build
 * @property {string} [previous_version] - Previously recorded version
 * @property {string} [version] - Version installed
 */
/**
 * @typedef BrowseFilterSelected
 * @property {string} collection_name - Browse collection name
 * @property {string} filter_name - Name of filter to narrow down or sort browsed content
 * @property {string} filter_value - Value of filter to narrow down or sort browsed content
 */
/**
 * @typedef BrowseGenreSelected
 * @property {string} collection_name - Browse collection name
 * @property {string} genre - The genre of the content e.g. "Comedy", "Action", "Adventure".
 */
/**
 * @typedef ChromecastConnected
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ChromecastDisconnected
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef ConcurrencyLimitReached
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [device_id] - Unique device identifier used to consume content
 */
/**
 * @typedef ContentAddedToMyList
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef MenuItemClicked
 * @property {string} menu_item - The menu item name
 */
/**
 * @typedef ContentCardClicked
 * @property {string} category_name - Rail title, channel group or other
 * @property {any[]} [contains] - Unique IDs of remaining content within same group/category
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {number} [content_position] - Position in rail (from left) or logical number (channel)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - "Show" or "Movie", or other context-dependent values
 * @property {string} [style] - Landscape, Portrait, Grid, Drawer
 * @property {string} view_name - screen or page card is being viewed on
 */
/**
 * @typedef SkipIntroSelected
 * @property {string} asset_id - Meadia Asset Id of video where SkipIntro was clicked
 * @property {string} title - Title of the video
 */
/**
 * @typedef SubtitleLanguageChanged
 * @property {string} [channel_id] - Channel ID
 * @property {string} [slot_id] - Video Slot
 * @property {string} subtitle - Subtitle language  selected
 * @property {string} title - Title of the video
 */
/**
 * @typedef WatchCreditSelected
 * @property {string} asset_id - Meadia Asset Id of video where WatchCredits was clicked
 * @property {string} title - Title of the video
 */
/**
 * @typedef NextEpisodeSelected
 * @property {string} asset_id - Meadia Asset Id of video where NextEpisode was clicked
 * @property {string} title - Title of the video
 */
/**
 * @typedef ContentDownloadCompleted
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - hero, program, channel, collection, listing, etc.
 * @property {number} [episode] - The episode number if applicable.
 * @property {number} [file_size] - Size of video asset file in bytes
 * @property {any[]} [genre] - The genre of the content, ie. ‘comedy’, ‘action’.
 * @property {boolean} [redownload] - True if content was downloaded again after expiration
 * @property {number} [season] - The season number if applicable.
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ContentDownloadRemoved
 * @property {boolean} [all] - Boolean indicator for content deletion
 */
/**
 * @typedef ContentDrawerOpened
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ContentRemovedFromMyList
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef DeviceRegistered
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef DeviceUpgradeDismissed
 * @property {any[]} [device_name] - Generic or user updated device name
 * @property {any[]} [settopbox_id] - Unique ID of SKY decoder (smartcard or serial number)
 */
/**
 * @typedef DeviceUpgradeEncountered
 * @property {any[]} [device_name] - Generic or user updated device name
 * @property {any[]} [settopbox_id] - Unique ID of SKY decoder (smartcard or serial number)
 */
/**
 * @typedef DeviceUpgradePursued
 * @property {any[]} [device_name] - Generic or user updated device name
 * @property {any[]} [settopbox_id] - Unique ID of SKY decoder (smartcard or serial number)
 */
/**
 * @typedef EmailVerified
 * @property {string} email - The user's email address (master billing account)
 */
/**
 * @typedef NotificationDismissed
 * @property {string} [notification_text] - Notification body metadata displayed to user
 * @property {string} notification_title - Notification title metadata displayed to user
 * @property {string} notification_type - Internal type (category) of notification displayed to user
 */
/**
 * @typedef NotificationSeen
 * @property {string} [notification_text] - Notification body metadata displayed to user
 * @property {string} notification_title - Notification title metadata displayed to user
 * @property {string} notification_type - Internal type (category) of notification displayed to user
 */
/**
 * @typedef OnScreenGuideProgramDetailsViewed
 * @property {string} channel - Linear channel name (or controlling channel name for VOD) in which the video content is playing.
 * @property {string} content_id - Id of content that was clicked (Skylark)
 * @property {string} content_title - The name of the show, movie, collection
 * @property {string} content_type - hero, program, channel, collection, listing, etc.
 * @property {boolean} livestream - True if linear programme is or will be a live (real-time) broadcast
 * @property {boolean} on_air - True if linear programme is on air at the moment
 */
/**
 * @typedef PageViewed
 * @property {string} [name] -
 * @property {string} [path] -
 * @property {string} [referrer] -
 * @property {string} [search] -
 * @property {string} [title] -
 * @property {string} [url] -
 */
/**
 * @typedef ParentalPinClassificationChanged
 * @property {string} [classification_code] - Content restriction classification code
 */
/**
 * @typedef ParentalPinControlChanged
 * @property {string} [availability] - State of functionality being able to be used or not
 */
/**
 * @typedef ParentalPinCreated
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ParentalPinEntered
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ParentalPinRequired
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef ParentalPinResetRequested
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef PermittedDeviceLimitReached
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 */
/**
 * @typedef PromotionalChannelSelected
 * @property {Record<string, any>} [name] - Name of installed app
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef RemoteRecordAccountLinkDismissed
 * @property {any[]} [device_name] - Generic or user updated device name
 * @property {any[]} [settopbox_id] - Unique ID of SKY decoder (smartcard or serial number)
 */
/**
 * @typedef RemoteRecordDismissed
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {string} [channel] - The channel in which the video content is playing
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - hero, program, channel, collection, listing, etc.
 * @property {number} [episode] - The episode number if applicable.
 * @property {any[]} [genre] - The genre of the content, ie. ‘comedy’, ‘action’.
 * @property {boolean} [livestream] - True if linear programme is or will be a live (real-time) broadcast
 * @property {number} [season] - The season number if applicable.
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef RemoteRecordScheduled
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {string} [channel] - The channel in which the video content is playing
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - hero, program, channel, collection, listing, etc.
 * @property {number} [days_from_today] - Number of days ahead from today for selected weekday
 * @property {string} [device_id] - Unique device identifier used to consume content
 * @property {string} [device_name] - Generic or user updated device name
 * @property {number} [episode] - The episode number if applicable.
 * @property {any[]} [genre] - The genre of the content, ie. ‘comedy’, ‘action’.
 * @property {boolean} [livestream] - True if linear programme is or will be a live (real-time) broadcast
 * @property {string} [record_type] - Type of remote record - episode or series
 * @property {number} [season] - The season number if applicable.
 * @property {string} [settopbox_id] - Unique ID of SKY decoder (smartcard or serial number)
 * @property {string} [view_name] - screen or page card is being viewed on
 * @property {string} [weekday_name] - TV guide selected weekday name
 */
/**
 * @typedef SearchNoResultReturned
 * @property {string} [query] - query that generate search results
 */
/**
 * @typedef ResultsSetItem
 * @property {string} [content_id] - Asset Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef SearchResultReturned
 * @property {string} [query] - query that generate search results
 * @property {ResultsSetItem[]} [results_set] - An array of all query matching results (title + asset ID)
 */
/**
 * @typedef SignedIn
 * @property {string} email - The user's email address (master billing account)
 */
/**
 * @typedef SignedOut
 * @property {string} email - The user's email address (master billing account)
 */
/**
 * @typedef ProfileSelected
 * @property {string} profile_name - The user's profile name
 */
/**
 * @typedef ProfileCreated
 * @property {string} profile_name - The user's profile name
 */
/**
 * @typedef ProfileDeleted
 * @property {string} profile_name - The user's profile name
 */
/**
 * @typedef ProfileNameChanged
 * @property {string} profile_name - The user's profile name
 */
/**
 * @typedef SignedUp
 * @property {string} email - The user's email address (master billing account)
 * @property {string} profile_name - The user's profile name
 * @property {string} registration_date - The user's first registration date and time
 */
/**
 * @typedef SpotifyAccountLinkInitiated
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef SpotifyAccountLinked
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef SpotifySoundtrackAddedToLibrary
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef SpotifySoundtrackPaused
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [track_id] - Spotify track ID for played content
 * @property {string} [track_title] - Spotify song title for played content
 */
/**
 * @typedef SpotifySoundtrackPlayed
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [track_id] - Spotify track ID for played content
 * @property {string} [track_title] - Spotify song title for played content
 */
/**
 * @typedef SpotifySoundtrackRemovedFromLibrary
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef SubscriptionUpgradeEncountered
 * @property {string} [category_name] - Rail title, channel group or other
 * @property {any[]} [channel] - The channel in which the video content is playing
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 * @property {string} [content_type] - hero, program, channel, collection, listing, etc.
 * @property {any[]} [subscription] - The name of current user subscription package
 * @property {any[]} [subscription_required] - The name of subscription required to play a content
 * @property {string} [view_name] - screen or page card is being viewed on
 */
/**
 * @typedef SubscriptionUpgradeMessageDismissed
 * @property {any[]} [subscription] - The name of current user subscription package
 * @property {any[]} [subscription_required] - The name of subscription required to play a content
 */
/**
 * @typedef SubscriptionUpgradePursued
 * @property {any[]} [subscription] - The name of current user subscription package
 * @property {any[]} [subscription_required] - The name of subscription required to play a content
 */
/**
 * @typedef TvGuideChannelGroupSelected
 * @property {string} [category_name] - Rail title, channel group or other
 */
/**
 * @typedef TvGuideMoreEpisodesSelected
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {string} [content_title] - The name of the show, movie, collection
 */
/**
 * @typedef TvGuideProgramDetailsViewed
 * @property {string} channel - Linear channel name (or controlling channel name for VOD) in which the video content is playing.
 * @property {string} content_id - Id of content that was clicked (Skylark)
 * @property {string} content_title - The name of the show, movie, collection
 * @property {string} content_type - hero, program, channel, collection, listing, etc.
 * @property {boolean} livestream - True if a linear programme is or will be a live (real-time) broadcast
 * @property {boolean} on_air - True if linear programme is on air at the moment
 */
/**
 * @typedef TvGuideWeekdaySelected
 * @property {number} [days_from_today] - Number of days ahead from today for selected weekday
 * @property {string} [method] - How weekday is selected e.g. scroll or select
 * @property {string} [weekday_name] - TV guide selected weekday name
 */
/**
 * @typedef VideoContentStarted
 * @property {any[]} channel - Linear channel name (or controlling channel name for VOD) in which the video content is playing.
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {number} duration - Total duration of the playback (in seconds).
 * @property {number} [episode] - The episode number if applicable.
 * @property {any[]} [genre] - The genre of the content e.g. "Comedy", "Action", "Adventure".
 * @property {boolean} [livestream] - True if linear programme is or will be a live (real-time) broadcast
 * @property {string} [network] - Indication whether user is streaming via mobile network, using wireless connection or is not connected to the Internet at all (offline mode)
 * @property {string} origin - Page or section the playback was initiated from.
 * @property {string} platform - Video player platform name e.g. "Web", "Mobile", "Tablet", "Chromecast", "AirPlay" or "TV".
 * @property {string} playback - Indication whether content is a linear stream or VOD.
 * @property {number} position - The current index position in seconds of the playhead.
 * @property {string} [quality] - The quality of the video either based on user settings or maximum bitrate for given network connection type
 * @property {number} [season] - The season number if applicable.
 * @property {string} title - Brand-level or movie title of the video content.
 * @property {string} [type] - Type of content e.g. "Movie", "Show".
 */
/**
 * @typedef VideoContentStopped
 * @property {any[]} channel - Linear channel name (or controlling channel name for VOD) in which the video content is playing.
 * @property {string} [content_id] - Id of content that was clicked (Skylark)
 * @property {number} duration - Total duration of the playback (in seconds).
 * @property {number} [episode] - The episode number if applicable.
 * @property {any[]} [genre] - The genre of the content e.g. "Comedy", "Action", "Adventure".
 * @property {boolean} [livestream] - True if linear programme is or will be a live (real-time) broadcast
 * @property {string} [network] - Indication whether user is streaming via mobile network, using wireless connection or is not connected to the Internet at all (offline mode)
 * @property {string} origin - Page or section the playback was initiated from.
 * @property {string} platform - Video player platform name e.g. "Web", "Mobile", "Tablet", "Chromecast", "AirPlay" or "TV".
 * @property {string} playback - Indication whether content is a linear stream or VOD.
 * @property {number} position - The current index position in seconds of the playhead.
 * @property {string} [quality] - The quality of the video either based on user settings or maximum bitrate for given network connection type
 * @property {number} [season] - The season number if applicable.
 * @property {string} title - Brand-level or movie title of the video content.
 * @property {string} [type] - Type of content e.g. "Movie", "Show".
 */
/**
 * @typedef VideoQualitySettingChanged
 * @property {string} setting - Video quality setting
 */
/**
 * @typedef WirelessDownloadSettingChanged
 * @property {string} [availability] - State of functionality being able to be used or not
 */

/**
 * User decides to continue with account linking process for their Set Top Box
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountLinkPursued(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Link Pursued',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered wrong PIN several times in a row
 *
 * @param {AccountLocked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountLocked(
	props?: AccountLocked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Locked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User does not have their account linked with Set Top Box for remote record functionality or they have Free To Air subscription only
 *
 * @param {AccountNotLinkedOrSubscriptionUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountNotLinkedOrSubscriptionUpgradeEncountered(
	props?: AccountNotLinkedOrSubscriptionUpgradeEncountered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Not Linked Or Subscription Upgrade Encountered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to update a device name
 *
 * @param {ActiveDeviceNameUpdateCancelled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activeDeviceNameUpdateCancelled(
	props?: ActiveDeviceNameUpdateCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Active Device Name Update Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates a device name
 *
 * @param {ActiveDeviceNameUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activeDeviceNameUpdated(
	props?: ActiveDeviceNameUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Active Device Name Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to remove a device from their active device list
 *
 * @param {ActiveDeviceRemovalCancelled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activeDeviceRemovalCancelled(
	props?: ActiveDeviceRemovalCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Active Device Removal Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User deletes a device from their active device list
 *
 * @param {ActiveDeviceRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activeDeviceRemoved(
	props?: ActiveDeviceRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Active Device Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User connects to their Apple TV via AirPlay
 *
 * @param {AirPlayConnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function airPlayConnected(
	props?: AirPlayConnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'AirPlay Connected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User disconnects from their Apple TV via AirPlay
 *
 * @param {AirPlayDisconnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function airPlayDisconnected(
	props?: AirPlayDisconnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'AirPlay Disconnected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User adds an application to their favourite apps rail
 *
 * @param {ApplicationAddedToFavourites} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationAddedToFavourites(
	props?: ApplicationAddedToFavourites,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Added To Favourites',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User backgrounds the application upon applicationDidEnterBackground
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationBackgrounded(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Backgrounded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The application crashes.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationCrashed(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Crashed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User first opens your mobile app. Collected automatically!
 *
 * @param {ApplicationInstalled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationInstalled(
	props?: ApplicationInstalled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Installed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens menu with installed apps to manage them
 *
 * @param {ApplicationManagerOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationManagerOpened(
	props: ApplicationManagerOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Manager Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User launches or foregrounds your mobile app after the first open Collected Automatically!
 *
 * @param {ApplicationOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationOpened(
	props?: ApplicationOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens panel to edit their favourite apps rail
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationPlacementManagerOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Placement Manager Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removes an application from their favourite apps rail
 *
 * @param {ApplicationRemovedFromFavourites} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationRemovedFromFavourites(
	props?: ApplicationRemovedFromFavourites,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Removed From Favourites',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User dismisses message to leave app review & rating in Apple App Store or Google Play Store
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationReviewDismissed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Review Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User submits app review & rating to Apple App Store or Google Play Store
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationReviewSubmitted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Review Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User uninstalls the application Often collected automatically!
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationUninstalled(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Uninstalled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates the app Collected automatically!
 *
 * @param {ApplicationUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationUpdated(
	props?: ApplicationUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User checks if fibre broadband is available at their address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function broadbandAvailabilityChecked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Broadband Availability Checked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Browse Sky Content" on About page and is redirected to Browse content catalog
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseContentSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse Content Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects any filter to narrow down browsed content
 *
 * @param {BrowseFilterSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseFilterSelected(
	props?: BrowseFilterSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse Filter Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects any genre to narrow down browsed content
 *
 * @param {BrowseGenreSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseGenreSelected(
	props: BrowseGenreSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse Genre Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects "Browse More Content" if no content is available for their subscription
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseMoreContentSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse More Content Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects "Reset Filters" if combination of filters yields no results
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseResetFiltersSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse Reset Filters Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Browse TV Guide" on About page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function browseTvGuideSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Browse TV Guide Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User connects to their Chromecast device
 *
 * @param {ChromecastConnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chromecastConnected(
	props?: ChromecastConnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chromecast Connected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User disconnects from their Chromecast device
 *
 * @param {ChromecastDisconnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chromecastDisconnected(
	props?: ChromecastDisconnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chromecast Disconnected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User reached maximum number of devices for playing content simultaneously
 *
 * @param {ConcurrencyLimitReached} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function concurrencyLimitReached(
	props?: ConcurrencyLimitReached,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Concurrency Limit Reached',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User adds piece of content to My List
 *
 * @param {ContentAddedToMyList} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentAddedToMyList(
	props?: ContentAddedToMyList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Added To My List',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Menu item clicked
 *
 * @param {MenuItemClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuItemClicked(
	props: MenuItemClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Item Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Generic event when any kind of card/tile is clicked
 *
 * @param {ContentCardClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentCardClicked(
	props: ContentCardClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Card Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Generic event when Skip Intro was clicked
 *
 * @param {SkipIntroSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skipIntroSelected(
	props: SkipIntroSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Skip Intro Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 *  Generic event when subtitle language changed
 *
 * @param {SubtitleLanguageChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subtitleLanguageChanged(
	props: SubtitleLanguageChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subtitle Language Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Generic event when Watch Credits was clicked
 *
 * @param {WatchCreditSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function watchCreditSelected(
	props: WatchCreditSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Watch Credit Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Generic event when Next Episode was clicked
 *
 * @param {NextEpisodeSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nextEpisodeSelected(
	props: NextEpisodeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Next Episode Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Content download in progress has been cancelled
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDownloadCancelled(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Download Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Content download successfully completed
 *
 * @param {ContentDownloadCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDownloadCompleted(
	props?: ContentDownloadCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Download Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User deletes previously downloaded content
 *
 * @param {ContentDownloadRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDownloadRemoved(
	props?: ContentDownloadRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Download Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated download to go for a piece of content
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDownloadStarted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Download Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens content drawer (e.g. with episodes or channels)
 *
 * @param {ContentDrawerOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentDrawerOpened(
	props?: ContentDrawerOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Drawer Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed piece of content from My List
 *
 * @param {ContentRemovedFromMyList} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contentRemovedFromMyList(
	props?: ContentRemovedFromMyList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Content Removed From My List',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User successfully registered a new device
 *
 * @param {DeviceRegistered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deviceRegistered(
	props?: DeviceRegistered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Device Registered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to go ahead with their Set Top Box device upgrade process
 *
 * @param {DeviceUpgradeDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deviceUpgradeDismissed(
	props?: DeviceUpgradeDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Device Upgrade Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User Set Top Box device does not support scheduled recordings
 *
 * @param {DeviceUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deviceUpgradeEncountered(
	props?: DeviceUpgradeEncountered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Device Upgrade Encountered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides to continue with upgrade process of their Set Top Box device
 *
 * @param {DeviceUpgradePursued} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deviceUpgradePursued(
	props?: DeviceUpgradePursued,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Device Upgrade Pursued',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User verifies their email used for registration
 *
 * @param {EmailVerified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailVerified(
	props?: EmailVerified,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Verified',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a notification from the list
 *
 * @param {NotificationDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notificationDismissed(
	props?: NotificationDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Notification Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opened notification list panel
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notificationPanelOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Notification Panel Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User was able to see and read a notification on the list
 *
 * @param {NotificationSeen} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notificationSeen(
	props?: NotificationSeen,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Notification Seen',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is no longer connected to the Internet
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offlineModeDetected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Offline Mode Detected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects "More Options" from on screen banner to see program details and possible actions
 *
 * @param {OnScreenGuideProgramDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onScreenGuideProgramDetailsViewed(
	props: OnScreenGuideProgramDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'On Screen Guide Program Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User switched to new version of web app
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function optedIn(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Opted In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User switched back to classic web app
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function optedOut(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Opted Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props?: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiates process to change their PIN
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinChangeInitiated(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Change Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changed restrictions around classification rating
 *
 * @param {ParentalPinClassificationChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinClassificationChanged(
	props?: ParentalPinClassificationChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Classification Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enabled or disabled parental PIN control
 *
 * @param {ParentalPinControlChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinControlChanged(
	props?: ParentalPinControlChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Control Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User created or modified their PIN
 *
 * @param {ParentalPinCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinCreated(
	props?: ParentalPinCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is prompted to create a PIN if not already created
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinCreationInitiated(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Creation Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User provided correct PIN in order to watch rated content
 *
 * @param {ParentalPinEntered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinEntered(
	props?: ParentalPinEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is asked to provide their PIN to watch rated content
 *
 * @param {ParentalPinRequired} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinRequired(
	props?: ParentalPinRequired,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Required',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User forgot their PIN or wants to reset it
 *
 * @param {ParentalPinResetRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function parentalPinResetRequested(
	props?: ParentalPinResetRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Parental PIN Reset Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User reached maximum number of devices permitted for registration
 *
 * @param {PermittedDeviceLimitReached} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function permittedDeviceLimitReached(
	props?: PermittedDeviceLimitReached,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Permitted Device Limit Reached',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a featured partner content within the Guide
 *
 * @param {PromotionalChannelSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionalChannelSelected(
	props?: PromotionalChannelSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotional Channel Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scans a QR code
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function qrCodeScanned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'QR Code Scanned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to go ahead with account linking process for their Set Top Box
 *
 * @param {RemoteRecordAccountLinkDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function remoteRecordAccountLinkDismissed(
	props?: RemoteRecordAccountLinkDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Remote Record Account Link Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to schedule any recording on their Set Top Box
 *
 * @param {RemoteRecordDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function remoteRecordDismissed(
	props?: RemoteRecordDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Remote Record Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Record" button
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function remoteRecordInitiated(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Remote Record Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scheduled recording for a piece of content on their Set Top Box
 *
 * @param {RemoteRecordScheduled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function remoteRecordScheduled(
	props?: RemoteRecordScheduled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Remote Record Scheduled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Screen Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function screenViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Screen Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User browse for content but nothing is found
 *
 * @param {SearchNoResultReturned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchNoResultReturned(
	props?: SearchNoResultReturned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search No Result Returned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User browse for content
 *
 * @param {SearchResultReturned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchResultReturned(
	props?: SearchResultReturned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Result Returned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User signs in to the service
 *
 * @param {SignedIn} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedIn(
	props: SignedIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Signed In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User signs out from the service
 *
 * @param {SignedOut} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedOut(
	props: SignedOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Signed Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User selects a profile
 *
 * @param {ProfileSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileSelected(
	props: ProfileSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Profile Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User creates a new profile
 *
 * @param {ProfileCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileCreated(
	props: ProfileCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Profile Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User deletes their profile
 *
 * @param {ProfileDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileDeleted(
	props: ProfileDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Profile Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides to change their profile name
 *
 * @param {ProfileNameChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileNameChanged(
	props: ProfileNameChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Profile Name Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User signs up for the service
 *
 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedUp(
	props: SignedUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Signed Up', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks Spotify Login button and starts account linking process
 *
 * @param {SpotifyAccountLinkInitiated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifyAccountLinkInitiated(
	props?: SpotifyAccountLinkInitiated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Account Link Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User linked their account with Spotify
 *
 * @param {SpotifyAccountLinked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifyAccountLinked(
	props?: SpotifyAccountLinked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Account Linked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User adds soundtrack album to their Spotify library
 *
 * @param {SpotifySoundtrackAddedToLibrary} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifySoundtrackAddedToLibrary(
	props?: SpotifySoundtrackAddedToLibrary,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Soundtrack Added To Library',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User stops soundtrack
 *
 * @param {SpotifySoundtrackPaused} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifySoundtrackPaused(
	props?: SpotifySoundtrackPaused,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Soundtrack Paused',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User plays soundtrack
 *
 * @param {SpotifySoundtrackPlayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifySoundtrackPlayed(
	props?: SpotifySoundtrackPlayed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Soundtrack Played',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removes soundtrack album from their Spotify library
 *
 * @param {SpotifySoundtrackRemovedFromLibrary} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spotifySoundtrackRemovedFromLibrary(
	props?: SpotifySoundtrackRemovedFromLibrary,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spotify Soundtrack Removed From Library',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User subscription is not sufficient to play a content
 *
 * @param {SubscriptionUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionUpgradeEncountered(
	props?: SubscriptionUpgradeEncountered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Upgrade Encountered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides not to pursue an upgrade of their subscription
 *
 * @param {SubscriptionUpgradeMessageDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionUpgradeMessageDismissed(
	props?: SubscriptionUpgradeMessageDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Upgrade Message Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User decides to continue with upgrade process of their subscription
 *
 * @param {SubscriptionUpgradePursued} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionUpgradePursued(
	props?: SubscriptionUpgradePursued,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Upgrade Pursued',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects channel group from TV Guide menu
 *
 * @param {TvGuideChannelGroupSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tvGuideChannelGroupSelected(
	props?: TvGuideChannelGroupSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'TV Guide Channel Group Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects "More Episodes" from expanded slot view
 *
 * @param {TvGuideMoreEpisodesSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tvGuideMoreEpisodesSelected(
	props?: TvGuideMoreEpisodesSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'TV Guide More Episodes Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects any programme slot for details on TV Guide
 *
 * @param {TvGuideProgramDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tvGuideProgramDetailsViewed(
	props: TvGuideProgramDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'TV Guide Program Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects weekday from TV Guide menu
 *
 * @param {TvGuideWeekdaySelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tvGuideWeekdaySelected(
	props?: TvGuideWeekdaySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'TV Guide Weekday Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The video content starts playing
 *
 * @param {VideoContentStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoContentStarted(
	props: VideoContentStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Content Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The video content stop playing
 *
 * @param {VideoContentStopped} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoContentStopped(
	props: VideoContentStopped,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Content Stopped',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changed their video quality setting for streaming
 *
 * @param {VideoQualitySettingChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoQualitySettingChanged(
	props?: VideoQualitySettingChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Video Quality Setting Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Watch Now" button on About page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function watchNowSelected(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Watch Now Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enabled or disabled downloads via Wi-Fi only
 *
 * @param {WirelessDownloadSettingChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wirelessDownloadSettingChanged(
	props?: WirelessDownloadSettingChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wireless Download Setting Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User decides to continue with account linking process for their Set Top Box
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountLinkPursued,
	/**
	 * User entered wrong PIN several times in a row
	 *
	 * @param {AccountLocked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountLocked,
	/**
	 * User does not have their account linked with Set Top Box for remote record functionality or they have Free To Air subscription only
	 *
	 * @param {AccountNotLinkedOrSubscriptionUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountNotLinkedOrSubscriptionUpgradeEncountered,
	/**
	 * User decides not to update a device name
	 *
	 * @param {ActiveDeviceNameUpdateCancelled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activeDeviceNameUpdateCancelled,
	/**
	 * User updates a device name
	 *
	 * @param {ActiveDeviceNameUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activeDeviceNameUpdated,
	/**
	 * User decides not to remove a device from their active device list
	 *
	 * @param {ActiveDeviceRemovalCancelled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activeDeviceRemovalCancelled,
	/**
	 * User deletes a device from their active device list
	 *
	 * @param {ActiveDeviceRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activeDeviceRemoved,
	/**
	 * User connects to their Apple TV via AirPlay
	 *
	 * @param {AirPlayConnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	airPlayConnected,
	/**
	 * User disconnects from their Apple TV via AirPlay
	 *
	 * @param {AirPlayDisconnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	airPlayDisconnected,
	/**
	 * User adds an application to their favourite apps rail
	 *
	 * @param {ApplicationAddedToFavourites} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationAddedToFavourites,
	/**
	 * User backgrounds the application upon applicationDidEnterBackground
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationBackgrounded,
	/**
	 * The application crashes.
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationCrashed,
	/**
	 * User first opens your mobile app. Collected automatically!
	 *
	 * @param {ApplicationInstalled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationInstalled,
	/**
	 * User opens menu with installed apps to manage them
	 *
	 * @param {ApplicationManagerOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationManagerOpened,
	/**
	 * User launches or foregrounds your mobile app after the first open Collected Automatically!
	 *
	 * @param {ApplicationOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationOpened,
	/**
	 * User opens panel to edit their favourite apps rail
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationPlacementManagerOpened,
	/**
	 * User removes an application from their favourite apps rail
	 *
	 * @param {ApplicationRemovedFromFavourites} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationRemovedFromFavourites,
	/**
	 * User dismisses message to leave app review & rating in Apple App Store or Google Play Store
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationReviewDismissed,
	/**
	 * User submits app review & rating to Apple App Store or Google Play Store
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationReviewSubmitted,
	/**
	 * User uninstalls the application Often collected automatically!
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationUninstalled,
	/**
	 * User updates the app Collected automatically!
	 *
	 * @param {ApplicationUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationUpdated,
	/**
	 * User checks if fibre broadband is available at their address
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	broadbandAvailabilityChecked,
	/**
	 * User clicks "Browse Sky Content" on About page and is redirected to Browse content catalog
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseContentSelected,
	/**
	 * User selects any filter to narrow down browsed content
	 *
	 * @param {BrowseFilterSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseFilterSelected,
	/**
	 * User selects any genre to narrow down browsed content
	 *
	 * @param {BrowseGenreSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseGenreSelected,
	/**
	 * User selects "Browse More Content" if no content is available for their subscription
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseMoreContentSelected,
	/**
	 * User selects "Reset Filters" if combination of filters yields no results
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseResetFiltersSelected,
	/**
	 * User clicks "Browse TV Guide" on About page
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	browseTvGuideSelected,
	/**
	 * User connects to their Chromecast device
	 *
	 * @param {ChromecastConnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chromecastConnected,
	/**
	 * User disconnects from their Chromecast device
	 *
	 * @param {ChromecastDisconnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chromecastDisconnected,
	/**
	 * User reached maximum number of devices for playing content simultaneously
	 *
	 * @param {ConcurrencyLimitReached} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	concurrencyLimitReached,
	/**
	 * User adds piece of content to My List
	 *
	 * @param {ContentAddedToMyList} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentAddedToMyList,
	/**
	 * Menu item clicked
	 *
	 * @param {MenuItemClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuItemClicked,
	/**
	 * Generic event when any kind of card/tile is clicked
	 *
	 * @param {ContentCardClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentCardClicked,
	/**
	 * Generic event when Skip Intro was clicked
	 *
	 * @param {SkipIntroSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skipIntroSelected,
	/**
	 *  Generic event when subtitle language changed
	 *
	 * @param {SubtitleLanguageChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subtitleLanguageChanged,
	/**
	 * Generic event when Watch Credits was clicked
	 *
	 * @param {WatchCreditSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	watchCreditSelected,
	/**
	 * Generic event when Next Episode was clicked
	 *
	 * @param {NextEpisodeSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	nextEpisodeSelected,
	/**
	 * Content download in progress has been cancelled
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentDownloadCancelled,
	/**
	 * Content download successfully completed
	 *
	 * @param {ContentDownloadCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentDownloadCompleted,
	/**
	 * User deletes previously downloaded content
	 *
	 * @param {ContentDownloadRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentDownloadRemoved,
	/**
	 * User initiated download to go for a piece of content
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentDownloadStarted,
	/**
	 * User opens content drawer (e.g. with episodes or channels)
	 *
	 * @param {ContentDrawerOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentDrawerOpened,
	/**
	 * User removed piece of content from My List
	 *
	 * @param {ContentRemovedFromMyList} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contentRemovedFromMyList,
	/**
	 * User successfully registered a new device
	 *
	 * @param {DeviceRegistered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deviceRegistered,
	/**
	 * User decides not to go ahead with their Set Top Box device upgrade process
	 *
	 * @param {DeviceUpgradeDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deviceUpgradeDismissed,
	/**
	 * User Set Top Box device does not support scheduled recordings
	 *
	 * @param {DeviceUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deviceUpgradeEncountered,
	/**
	 * User decides to continue with upgrade process of their Set Top Box device
	 *
	 * @param {DeviceUpgradePursued} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deviceUpgradePursued,
	/**
	 * User verifies their email used for registration
	 *
	 * @param {EmailVerified} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailVerified,
	/**
	 * User removed a notification from the list
	 *
	 * @param {NotificationDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notificationDismissed,
	/**
	 * User opened notification list panel
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notificationPanelOpened,
	/**
	 * User was able to see and read a notification on the list
	 *
	 * @param {NotificationSeen} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notificationSeen,
	/**
	 * User is no longer connected to the Internet
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offlineModeDetected,
	/**
	 * User selects "More Options" from on screen banner to see program details and possible actions
	 *
	 * @param {OnScreenGuideProgramDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onScreenGuideProgramDetailsViewed,
	/**
	 * User switched to new version of web app
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	optedIn,
	/**
	 * User switched back to classic web app
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	optedOut,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User initiates process to change their PIN
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinChangeInitiated,
	/**
	 * User changed restrictions around classification rating
	 *
	 * @param {ParentalPinClassificationChanged} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinClassificationChanged,
	/**
	 * User enabled or disabled parental PIN control
	 *
	 * @param {ParentalPinControlChanged} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinControlChanged,
	/**
	 * User created or modified their PIN
	 *
	 * @param {ParentalPinCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinCreated,
	/**
	 * User is prompted to create a PIN if not already created
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinCreationInitiated,
	/**
	 * User provided correct PIN in order to watch rated content
	 *
	 * @param {ParentalPinEntered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinEntered,
	/**
	 * User is asked to provide their PIN to watch rated content
	 *
	 * @param {ParentalPinRequired} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinRequired,
	/**
	 * User forgot their PIN or wants to reset it
	 *
	 * @param {ParentalPinResetRequested} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	parentalPinResetRequested,
	/**
	 * User reached maximum number of devices permitted for registration
	 *
	 * @param {PermittedDeviceLimitReached} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	permittedDeviceLimitReached,
	/**
	 * User selects a featured partner content within the Guide
	 *
	 * @param {PromotionalChannelSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionalChannelSelected,
	/**
	 * User scans a QR code
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	qrCodeScanned,
	/**
	 * User decides not to go ahead with account linking process for their Set Top Box
	 *
	 * @param {RemoteRecordAccountLinkDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	remoteRecordAccountLinkDismissed,
	/**
	 * User decides not to schedule any recording on their Set Top Box
	 *
	 * @param {RemoteRecordDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	remoteRecordDismissed,
	/**
	 * User clicks "Record" button
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	remoteRecordInitiated,
	/**
	 * User scheduled recording for a piece of content on their Set Top Box
	 *
	 * @param {RemoteRecordScheduled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	remoteRecordScheduled,
	/**
	 * Fires a 'Screen Viewed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	screenViewed,
	/**
	 * User browse for content but nothing is found
	 *
	 * @param {SearchNoResultReturned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchNoResultReturned,
	/**
	 * User browse for content
	 *
	 * @param {SearchResultReturned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchResultReturned,
	/**
	 * User signs in to the service
	 *
	 * @param {SignedIn} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedIn,
	/**
	 * User signs out from the service
	 *
	 * @param {SignedOut} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedOut,
	/**
	 * User selects a profile
	 *
	 * @param {ProfileSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	profileSelected,
	/**
	 * User creates a new profile
	 *
	 * @param {ProfileCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	profileCreated,
	/**
	 * User deletes their profile
	 *
	 * @param {ProfileDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	profileDeleted,
	/**
	 * User decides to change their profile name
	 *
	 * @param {ProfileNameChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	profileNameChanged,
	/**
	 * User signs up for the service
	 *
	 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedUp,
	/**
	 * User clicks Spotify Login button and starts account linking process
	 *
	 * @param {SpotifyAccountLinkInitiated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifyAccountLinkInitiated,
	/**
	 * User linked their account with Spotify
	 *
	 * @param {SpotifyAccountLinked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifyAccountLinked,
	/**
	 * User adds soundtrack album to their Spotify library
	 *
	 * @param {SpotifySoundtrackAddedToLibrary} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifySoundtrackAddedToLibrary,
	/**
	 * User stops soundtrack
	 *
	 * @param {SpotifySoundtrackPaused} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifySoundtrackPaused,
	/**
	 * User plays soundtrack
	 *
	 * @param {SpotifySoundtrackPlayed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifySoundtrackPlayed,
	/**
	 * User removes soundtrack album from their Spotify library
	 *
	 * @param {SpotifySoundtrackRemovedFromLibrary} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spotifySoundtrackRemovedFromLibrary,
	/**
	 * User subscription is not sufficient to play a content
	 *
	 * @param {SubscriptionUpgradeEncountered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionUpgradeEncountered,
	/**
	 * User decides not to pursue an upgrade of their subscription
	 *
	 * @param {SubscriptionUpgradeMessageDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionUpgradeMessageDismissed,
	/**
	 * User decides to continue with upgrade process of their subscription
	 *
	 * @param {SubscriptionUpgradePursued} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionUpgradePursued,
	/**
	 * User selects channel group from TV Guide menu
	 *
	 * @param {TvGuideChannelGroupSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tvGuideChannelGroupSelected,
	/**
	 * User selects "More Episodes" from expanded slot view
	 *
	 * @param {TvGuideMoreEpisodesSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tvGuideMoreEpisodesSelected,
	/**
	 * User selects any programme slot for details on TV Guide
	 *
	 * @param {TvGuideProgramDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tvGuideProgramDetailsViewed,
	/**
	 * User selects weekday from TV Guide menu
	 *
	 * @param {TvGuideWeekdaySelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tvGuideWeekdaySelected,
	/**
	 * The video content starts playing
	 *
	 * @param {VideoContentStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoContentStarted,
	/**
	 * The video content stop playing
	 *
	 * @param {VideoContentStopped} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoContentStopped,
	/**
	 * User changed their video quality setting for streaming
	 *
	 * @param {VideoQualitySettingChanged} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoQualitySettingChanged,
	/**
	 * User clicks "Watch Now" button on About page
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	watchNowSelected,
	/**
	 * User enabled or disabled downloads via Wi-Fi only
	 *
	 * @param {WirelessDownloadSettingChanged} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wirelessDownloadSettingChanged,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/sky-as-an-app/protocols/tracking-plans/rs_1V2QN6yXqcPKMzUveh7PhGNWvLm`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
