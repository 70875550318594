import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { useAuth0 } from '@auth0/auth0-react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getApiHeaders } from '@/Utils/Sentry';

const useApollo = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const asyncAuthLink = setContext(async () => {
    let token: string | null = null;
    if (isAuthenticated) {
      token = await getAccessTokenSilently();
    }
    const headers: any = {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      'Content-Type': 'application/json',
      ...getApiHeaders(),
    };
    return {
      headers,
    };
  });

  const sentryErrorLink = onError(({ operation, graphQLErrors, networkError }) => {
    Sentry.withScope(scope => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
        query: operation.query,
      });

      graphQLErrors?.forEach(gqlError => {
        const error = new Error(gqlError.message);
        error.stack = operation.getContext().clientStackTrace;
        Sentry.captureException(error, {
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              gqlError,
              message: gqlError.message,
              extensions: gqlError.extensions,
            },
          },
        });
      });

      if (networkError) {
        const error = new Error(networkError.message);
        error.stack = operation.getContext().clientStackTrace;
        Sentry.captureException(error, {
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  });

  const httpLink = new HttpLink({
    uri: `${process.env.EXP_API_URL}/exp/graph`,
  });

  const apolloClient = new ApolloClient({
    link: from([sentryErrorLink, asyncAuthLink.concat(httpLink)]), // asyncAuthLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return { apolloClient };
};

export default useApollo;
