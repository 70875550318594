import { useCallback } from 'react';
import qs from 'query-string';
import { isEmpty, isNil } from 'ramda';
import { useHistory, generatePath } from 'react-router-dom';

import { useSubscriptionModal, useChannelSubscriptions } from '@/Hooks/useSubscription';
import RoutePath from '@/Routes/RoutePath';
import { getViewPage } from '@/Utils/PageLocation';
import { utils } from '@/Store';
import { LinearChannel, LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import useDevice from './useDevice';

const useLivePlayer = () => {
  const history = useHistory();
  const device = useDevice();
  const getRequiredSubscriptions = useChannelSubscriptions();
  const showUpgradeSubscriptionModal = useSubscriptionModal();

  const openAndPlay = useCallback(
    (
      channel: LinearChannel,
      slot: LinearSlot,
      playbackOrigin?: string,
      startOffset?: number,
      routerHistory?: any,
      ignoreEntitlementCheck?: boolean,
      referrer?: string,
    ) => {
      // Device registration check
      if (device.isDeviceRegistrationLimitExceeded) {
        device.showDeviceRegistrationLimitModal();
        return;
      }

      const runtimeHistory = routerHistory || history;

      // Entitlement subscription check
      const requiredSubscriptions = getRequiredSubscriptions(channel?.id);

      // Entitlement subscription check
      if (
        !ignoreEntitlementCheck &&
        !isNil(requiredSubscriptions) &&
        !isEmpty(requiredSubscriptions)
      ) {
        const analyticsParams: any = {
          categoryName: playbackOrigin,
          contentId: channel?.id,
          contentTitle: utils.analytics.getSegmentSlotTitle(slot),
          contentType: utils.analytics.getContentTypeBySlot(slot),
          channelName: utils.analytics.getSegmentSlotChannel(channel),
          viewName: getViewPage(runtimeHistory),
        };

        const subscriptions = channel?.schedule?.subscriptions;
        showUpgradeSubscriptionModal(subscriptions, analyticsParams);
        return;
      }
      const livePath = qs.stringifyUrl({
        url: generatePath(RoutePath.watchTv, { channelId: channel.id }),
        query: {
          startOffset,
          playerReferrer: referrer || playbackOrigin,
        },
      });

      runtimeHistory?.push(livePath);
    },
    [device, history, getRequiredSubscriptions, showUpgradeSubscriptionModal],
  );

  return { openAndPlay };
};

export default useLivePlayer;
