enum RailGroup {
  OnAirSlot = 'OnAirSlotGroup',
  FavouriteApps = 'FavouriteAppsGroup',
  MediumRail = 'MediumRailGroup',
  ContinueWatching = 'ContinueWatchingGroup',
  LargeRail = 'LargeRailGroup',
  SmallRail = 'SmallRailGroup',
  FeatureRail = 'FeatureRailGroup',
}
export default RailGroup;
