import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import QRCode from 'qrcode.react';
import qs from 'query-string';
import { Logo } from 'Assets/all';
import { ReactComponent as WatchTVSVG } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_watch_tv.svg';
import { ReactComponent as BrowseSVG } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_browse.svg';
import { ReactComponent as ParentalSVG } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_parental.svg';
import { ReactComponent as AppStoreLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_app_store_download.color.svg';
import { ReactComponent as PlayStoreLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_play_store_download.color.svg';
import { AboutPage } from '@/Analytics';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import Content from '@/Layouts/containers/Content';
import { Link, generatePath } from 'react-router-dom';
import RoutePath from '@/Routes/RoutePath';
import { QUERY_REDIRECT_TO, REDIRECT_TO_APP_STORE } from '@/Pages/Redirect';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/Utils/StoreLinks';
import pageViewedAnalytics from '@/Analytics/Page';
import useScroll from '@/Hooks/useScroll';
import useMedia from '@/Hooks/useMedia';
import headerImg from '@/Resources/images/about/header.png';
import bigRailImg from '@/Resources/images/about/bigRail.png';
import smallRailImg from '@/Resources/images/about/smallRail.png';
import ipadImg from '@/Resources/images/about/ipad.png';
import laptopImg from '@/Resources/images/about/laptop.png';
import watchFavImg from '@/Resources/images/about/watchFav.png';
import downloadImg from '@/Resources/images/about/download.png';
import recordImg from '@/Resources/images/about/record.png';
import castingImg from '@/Resources/images/about/casting.png';

import styles from './styles.module.scss';

const About: React.FC = () => {
  usePageHeader({
    title: 'About',
    description:
      'Sky Go is a lifesaver if you’re on the move, you can live stream epic movies, exciting live sports events and your favourite shows straight to your device. Download the free app to experience content on the go.',
  });
  useEffect(() => {
    pageViewedAnalytics('About');
  }, []);
  const { isMediaM } = useMedia();
  const [contentImgTop, setContentImgTop] = useState(0);

  const qrCodeURL = qs.stringifyUrl({
    url: `${window.location.origin}${RoutePath.redirect}`,
    query: {
      [QUERY_REDIRECT_TO]: REDIRECT_TO_APP_STORE,
      utm_medium: 'organic',
      utm_source: 'web',
      utm_campaign: 'SkyGo_brand_promo',
    },
  });

  const { nodeRef } = useScroll({
    wait: 0,
    scrollHandler: ({ top, bottom }) => {
      if (!isMediaM) {
        return;
      }
      let offset = top - window.innerHeight / 3;
      const bottomOffset = bottom - window.innerHeight / 2;
      // the content image starts scrolling when the container's top is 1/3 from the screen top
      if (offset > 0) {
        offset = 0;
        // the content image stop scroll when the container's bottom is 1/2 from the screen top
      } else if (bottomOffset < 0) {
        offset = contentImgTop;
      }
      setContentImgTop(offset);
    },
  });

  return (
    <div className={styles.about} data-testid="about-container">
      <div className={styles.section}>
        <Logo className={styles.appLogoIcon} />
        <Content className={classnames(styles.textSection, styles.titleTextSection)}>
          <p className={styles.pageTitle} data-testid="about-page-title">
            It’s Sky that goes with you
          </p>
        </Content>
        <Content className={styles.textSection}>
          <p className={styles.textSectionDesc}>
            It’s new. It’s improved. And it’s ready for you to download. The Sky Go app is free for
            Sky customers with a Sky Box in their home and lets you watch on any device, anywhere in
            New Zealand so you can take Sky with you.
          </p>
          <div className={styles.buttonContainer}>
            <Link to={`${generatePath(RoutePath.watchTv)}?playerReferrer=About`}>
              <Button
                className={styles.button}
                type="primary"
                data-testid="about-top-watch-button"
                name="Watch now"
                onClick={AboutPage.watchNowClicked}
              >
                WATCH NOW
              </Button>
            </Link>
          </div>
        </Content>
        <Content className={styles.headerImgContainer}>
          <img src={headerImg} alt="Massive contents in SkyGo" />
        </Content>
      </div>
      <div className={styles.section}>
        <Content className={classnames(styles.textSection, styles.favTextSection)}>
          <p className={styles.textSectionTitle}>Watch all your favourites</p>
          <p className={styles.textSectionDesc}>
            It’s now easier than ever to find the movies, shows and sport you want to watch, Live
            and On Demand.
          </p>
        </Content>
        <div className={styles.favSectionImgContainer}>
          {isMediaM ? (
            <>
              <img src={laptopImg} alt="View SkyGo in laptop" className={styles.laptopImg} />
              <div
                className={styles.contentImg}
                style={{ backgroundPositionY: `${contentImgTop}px` }}
                ref={nodeRef}
              />
            </>
          ) : (
            <img
              src={watchFavImg}
              alt="View SkyGo in laptop"
              className={styles.watchFavImg}
              ref={nodeRef}
            />
          )}
        </div>
      </div>
      <div className={classnames(styles.section, styles.downloadSection)}>
        <Content className={classnames(styles.textSection, styles.downloadTextContainer)}>
          <p className={styles.textSectionTitle}>Download to Go</p>
          <p className={styles.textSectionDesc}>
            Download selected shows and movies to your device when connected to Wi-Fi, so you can
            watch anywhere.
          </p>
          <div className={styles.buttonContainer}>
            <Link to={generatePath(RoutePath.browse)}>
              <Button
                className={styles.button}
                type="primary"
                data-testid="about-browse-content-button"
                name="Browse Sky content"
                onClick={AboutPage.browseContentClicked}
              >
                Browse Sky content
              </Button>
            </Link>
          </div>
        </Content>
        <img src={downloadImg} alt="Download to Go" className={styles.downloadImg} />
      </div>
      <div className={classnames(styles.section, styles.recordSection)}>
        <Content className={styles.textSection}>
          <p className={styles.textSectionTitle}>Record to watch later</p>
          <p className={styles.textSectionDesc}>
            Browse the TV Guide and remote record to your My Sky Box from anywhere in New Zealand so
            you never miss a show.
          </p>
        </Content>
        <img src={recordImg} alt="Record to watch later" className={styles.recordImg} />
      </div>
      <div className={styles.section}>
        <Content className={classnames(styles.textSection, styles.castingTextSection)}>
          <p className={styles.textSectionTitle}>Cast to your TV</p>
          <p className={styles.textSectionDesc}>
            Use Chromecast or AirPlay to cast all the action to your big screen from the comfort of
            your home.
          </p>
        </Content>
        <img src={castingImg} alt="Cast to TV" className={styles.castingImg} />
      </div>
      <div className={styles.section}>
        <p className={styles.textSectionTitle}>What else?</p>
        <Content className={styles.moreSection}>
          <div className={styles.iconContainer}>
            <WatchTVSVG />
            <p className={styles.iconTitle}>Watch Live TV</p>
            <p className={styles.iconDesc}>
              Watch your favourite movies, shows and sport anytime, anywhere in NZ.
            </p>
          </div>
          <div className={styles.iconContainer}>
            <BrowseSVG />
            <p className={styles.iconTitle}>Browse</p>
            <p className={styles.iconDesc}>
              Browse by genre, channel, your subscription or even what’s new so you will always be
              able to find something good to watch.
            </p>
          </div>
          <div className={styles.iconContainer}>
            <ParentalSVG />
            <p className={styles.iconTitle}>Parental control</p>
            <p className={styles.iconDesc}>
              Keep content family friendly with parental control settings.
            </p>
          </div>
        </Content>
        <div className={styles.buttonContainer}>
          <Link to={`${generatePath(RoutePath.watchTv)}?playerReferrer=About`}>
            <Button
              className={styles.button}
              type="primary"
              data-testid="about-bottom-watch-button"
              name="Watch now"
              onClick={AboutPage.watchNowClicked}
            >
              WATCH NOW
            </Button>
          </Link>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.tabletContainer}>
          <div className={styles.railImgContainer}>
            <img src={bigRailImg} alt="Content rail in tablet" className={styles.bigRailImg} />
            <img
              src={smallRailImg}
              alt="Small content rail in tablet"
              className={styles.smallRailImg}
            />
          </div>
          <div className={styles.deviceFrame} />
          <img src={ipadImg} alt="View SkyGo in tablet" className={styles.ipadImg} />
          <div className={styles.ipadContainer}>
            <Logo className={styles.appLogoIcon} />
            <p className={styles.textSectionTitle}>Download the App</p>
            <p className={styles.textSectionDesc}>Scan the code to get the App.</p>
            <QRCode value={qrCodeURL} renderAs="svg" className={styles.qrcodeImg} />
            <div>
              <a
                href={IOS_APP_LINK}
                data-testid="tablet-app-store-logo"
                className={styles.storeLogoLink}
              >
                <AppStoreLogo className={styles.storelogo} />
              </a>
              <a
                href={ANDROID_APP_LINK}
                data-testid="tablet-play-store-logo"
                className={styles.storeLogoLink}
              >
                <PlayStoreLogo className={styles.storelogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Content className={styles.noteSection}>
        <p className={styles.textSectionDesc}>
          Please note: not all content on Sky is available on Sky Go as we don&apos;t always have
          the rights for digital streaming.
        </p>
      </Content>
    </div>
  );
};

export default About;
