import { useQuery, gql, useApolloClient } from '@apollo/client';
import { MenuItem } from '@/Utils/NavigationMenuItems';
import { PredefinedSectionId } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { ResultOf } from '@graphql-typed-document-node/core';
import { Section } from '@/lib/graph-ql';

type RootSections = {
  data: MenuItem[] | undefined;
  filterData: MenuItem[] | undefined;
  loading: boolean;
  error: boolean;
  refetchRootSections: () => void;
};
const getMenuItemByName = (item: Section): MenuItem | undefined => {
  return {
    id: item.id,
    key: item.id,
    label: item.title,
    path: item?.home?.path,
    icon: item?.icon?.uri,
    rootSectionId: item.id,
    alignment: item.alignment,
    predefinedId: item?.home?.__typename === 'ContentHome' ? 'HOME' : item?.predefinedId,
  };
};
const ROOTSECTION_FRAGMENT = `fragment RootSectionFragment on Section {
    id
    predefinedId
    title
    alignment
    icon {
      uri
    }

    home {
      __typename
      ... on ContentHome {
        path
      }
      ... on BrowseHome {
        path
      }
      ... on WatchTvHome {
        path
      }
      ... on EmptyHome {
        path
      }
    }
}`;

export const ROOT_SECTIONS_QUERY = gql`
  query getRootSections {
    rootSections {
      ...RootSectionFragment
    }
  }
  ${ROOTSECTION_FRAGMENT}
`;
interface RootSectionsData {
  rootSections: ResultOf<typeof ROOTSECTION_FRAGMENT>[];
}

const useRootSections = (): RootSections => {
  const client = useApolloClient();
  const { data, error, loading } = useQuery<RootSectionsData>(ROOT_SECTIONS_QUERY);

  const refetchRootSections = () => {
    client.refetchQueries({ include: [ROOT_SECTIONS_QUERY] });
  };

  let rootSections;
  if (data?.rootSections) {
    const menuList: MenuItem[] = [];
    data.rootSections.forEach((item: Section) => {
      const tab = getMenuItemByName(item);
      if (tab) {
        menuList.push(tab);
      }
    });
    rootSections = menuList;
  }

  /*
   * When a refetchRootSection occurs and the last selected route is removed from the backend, the navigation will automatically advance to the top screen in the stack(ex. BROWSE).
   * In that scenario, however, we would navigate to 'HOME' per our demand.
   * In order to fix that, we moved the home page to the top of the drawerList without change order of Drawer Menu.
   */
  let filterData;
  if (rootSections) {
    const homeIndex = rootSections.findIndex(
      item => item.predefinedId === PredefinedSectionId.Home,
    );
    if (homeIndex === -1) {
      filterData = rootSections;
    } else {
      const newData = [...rootSections];
      const homeItem = newData.splice(homeIndex, 1)[0];
      filterData = [homeItem, ...newData];
    }
  }

  return {
    data: rootSections,
    filterData,
    loading,
    error: !!error,
    refetchRootSections,
  };
};

export default useRootSections;
