import { matchPath } from 'react-router-dom';

const RoutePath = {
  linkAccountCallback: '/link-account-callback',
  login: '/login',
  register: '/register',
  logout: '/logout',
  home: '/',
  tvGuide: '/tv-guide/:channelId?',
  search: '/search',
  show: '/show/:brandId',
  movie: '/movie/:brandId',
  collection: '/collection/:collectionId',
  watch: '/watch/:brandId/:episodeId?',
  watchTv: '/watch-tv/:channelId?',
  browse: '/browse',
  browseCollection: '/browse/:collectionId',
  about: '/about',
  redirect: '/redirect',
  profile: '/profile/manage',
  deviceLogin: '/device-login',
  deviceLogout: '/device-logout',
};

export const isHomePagesPath = (pathname: string) => {
  const HomePagesPath = [RoutePath.home, RoutePath.watchTv];
  return HomePagesPath.some(homePage => {
    const match = matchPath(pathname, {
      path: homePage,
      exact: true,
    });
    return match?.isExact;
  });
};

export const isProfilePage = (pathname: string) => {
  const match = matchPath(pathname, {
    path: RoutePath.profile,
    exact: true,
  });
  return match?.isExact;
};
export default RoutePath;
