import React, { FC, useCallback, useMemo } from 'react';
import variables from 'Styles/variables';
import { components } from 'react-select';
import classnames from 'classnames';
import useAuth from '@/Hooks/useAuth';
import useCustomerProfile from '@/Hooks/useCustomerProfile';
import { getLoginUrl } from '@/Utils/redirectToLogin';
import { useHistory } from 'react-router';
import useMedia from '@/Hooks/useMedia';
import RoutePath from '@/Routes/RoutePath';
import { useDispatch } from 'react-redux';
import { actions } from '@/Store';
import greyProfileIcon from '@skytvnz/sky-app-style/lib/assets/svg/backgrounds/icon_manage_profile.color.svg';
import signOutIcon from '@skytvnz/sky-app-style/lib/assets/svg/backgrounds/icon_sign_out.color.svg';
import skyGoIcon from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_skyAbout_logo.svg';
import styles from './styles.module.scss';
import Avatar from '../Avatar';
import DropDown, { DropDownItem, DropDownProps } from './DropDown';
import { SIGN_OUT, MANAGE_PROFILE, SIGN_IN, SKY_GO_ABOUT, REGISTER } from './constants';

export interface ProfileDropDownItem extends DropDownItem {
  avatar?: string;
}
interface ProfileDropDownProps extends DropDownProps {
  fontColor?: string;
  backgroundColor?: string;
  focusBackgroundColor?: string;
  className?: string;
  testId?: string;
}

const ProfileDropDown: FC<ProfileDropDownProps> = ({
  className,
  fontColor = '#fff',
  backgroundColor = variables.colorPaletteStorm90,
  focusBackgroundColor = variables.colorPalettePurpleDark,
}) => {
  const { signOut, isAuthenticated } = useAuth();
  const { isProfileLoading, selectedProfile, customerProfiles } = useCustomerProfile();
  const loginUrl = getLoginUrl();
  const registerUrl = getLoginUrl(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMediaM } = useMedia();
  const options = useMemo<ProfileDropDownItem[]>(
    () =>
      customerProfiles?.map(({ id, name, customerProfileAvatar }) => ({
        label: name,
        avatar: customerProfileAvatar?.image?.uri,
        value: id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerProfiles],
  );

  const selectedOption = options?.find(({ value }) => selectedProfile?.id === value);

  const menuOptions = useMemo(() => {
    return !isAuthenticated
      ? [
          { label: 'Register', value: REGISTER },
          { label: 'Sign In', value: SIGN_IN },
        ]
      : options && [
          ...options,
          { avatar: greyProfileIcon, label: 'Manage Profiles', value: MANAGE_PROFILE },
          { icon: skyGoIcon, label: 'About Sky Go', value: SKY_GO_ABOUT },
          { avatar: signOutIcon, label: 'Sign Out', value: SIGN_OUT },
        ];
  }, [isAuthenticated, options]);

  const manageDropdownChange = OptionSelected => {
    if (OptionSelected.value === SIGN_OUT) {
      signOut();
    } else if (OptionSelected.value === MANAGE_PROFILE) {
      history.push(RoutePath.profile);
    } else if (OptionSelected.value === SIGN_IN) {
      history.push(loginUrl);
    } else if (OptionSelected.value === REGISTER) {
      history.push(registerUrl);
    } else if (OptionSelected.value === SKY_GO_ABOUT) {
      history.push(RoutePath.about);
    } else {
      dispatch(actions.customer.setSelectedProfileId(OptionSelected.value));
    }
  };

  const customStyles = useMemo(
    () => ({
      menu: provided => ({
        ...provided,
        width: '215px',
        boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.25)',
        border: '1px solid rgba(255, 255, 255, 0.25)',
        borderRadius: '10px',
        backgroundColor,
        color: fontColor,
        marginTop: '6px',
        zIndex: 99,
        right: '84%',
      }),

      menuList: provided => ({
        ...provided,
        color: '#fff8',
        borderRadius: '4px',
        height: 'auto',
        padding: '4px',
      }),

      control: provided => ({
        ...provided,
        border: 'none',
        backgroundColor: 'none',
        '&:hover': { borderColor: 'none' },
        boxShadow: 'none',
        color: fontColor,
      }),

      valueContainer: provided => ({
        ...provided,
        position: 'absolute',
        justifyContent: 'flex-end',
        paddingRight: '8px',
        paddingLeft: '0px',
      }),

      singleValue: provided => ({
        ...provided,
        color: fontColor,
        cursor: 'pointer',
      }),

      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused && focusBackgroundColor,
        color: '#ffffff',
        fontWeight: '400',
        height: '49px',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '8px',
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        paddingLeft: '4px',
        paddingRight: '4px',
      }),
    }),
    [backgroundColor, focusBackgroundColor, fontColor],
  );

  const Option = useCallback(props => {
    const { data } = props;
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.Option {...props}>
        {data.avatar && <Avatar profileImage={data.avatar} size={24} isActive />}
        {data.icon && (
          <img
            src={data.icon}
            alt="About Sky Go"
            style={{
              width: 28,
              height: 16,
              filter: 'contrast(0.1) brightness(150%)',
            }}
          />
        )}
        <span
          data-testid={`dropdown-option-${data.value}`}
          className={
            data.avatar
              ? classnames(styles.profileOptions, styles.profileOptionBold)
              : styles.profileOptions
          }
        >
          {data.label}
        </span>
      </components.Option>
    );
  }, []);

  const IndicatorsContainer = useCallback(
    ({ children, ...props }) => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.IndicatorsContainer {...props} className={styles.indicatorsContainer}>
          {children}
          <Avatar
            profileImage={selectedOption?.avatar ?? undefined}
            size={32}
            isActive
            className={styles.profileAvatar}
          />
        </components.IndicatorsContainer>
      );
    },
    [selectedOption],
  );

  const SingleValue = useCallback(
    ({ children, ...props }) => {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.SingleValue {...props} className={styles.singleValue}>
          {isMediaM && children}
        </components.SingleValue>
      );
    },
    [isMediaM],
  );

  const ValueContainer = useCallback(({ children, ...props }) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.ValueContainer {...props} className={styles.valueContainer}>
        {children}
      </components.ValueContainer>
    );
  }, []);

  return !isProfileLoading ? (
    <DropDown
      customStyles={customStyles}
      className={classnames(styles.profileDropDown, className)}
      options={menuOptions}
      onChange={manageDropdownChange}
      selectedValue={selectedProfile?.id}
      customComponents={{
        ValueContainer,
        IndicatorsContainer,
        SingleValue,
        Option,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  ) : null;
};

export default ProfileDropDown;
