import React, { useCallback, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { isEmpty, isNil } from 'ramda';

import MediaTile from 'ComponentOverrides/Rail/Tiles/MediaTile';

import { utils } from '@/Store';
import Heading from '@/Components/Heading';
import Content from '@/Layouts/containers/Content';
import useCurrent from '@/Hooks/useCurrent';
import ServiceTile from '@/Components/Rail/Tiles/ServiceTile';
import ContentType from '@/Utils/ContentTypes';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import { GroupLayout, StandardAspectRatio } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { formatGenres } from '@/Utils/Genres';
import formatLayout from '@/Utils/LayoutFormatter';
import { getVideoImageType } from '@/Utils/VideoImage';
import contentCardClickedAnalytics from '@/Analytics/ContentCard';
import cardClickAnalytics from '@/Analytics/Rail';
import usePersistCallback from '@/Hooks/usePersistCallback';
import RailGroup from '@/types/enums/RailGroup';

import TileType from './Tiles/TileType';
import TilesList from '../TilesList';
import SkeletonTile from './Tiles/MediaTile/SkeletonTile';
import useAiringSlotModal from './AiringSlotModalDetails/useAiringSlotModal';

export enum PageType {
  Home = 'Home',
  Details = 'Details',
}

interface RailProps {
  id?: string;
  heading: string;
  subTitle?: string;
  viewName?: string;
  assets?: any[];
  type?: TileType;
  layout?: GroupLayout;
  railGroup?: RailGroup;
  aspectRatio?: StandardAspectRatio;
  size?: TileSize;
  isSkeletonLoadingEnabled?: boolean;
  hasNextPage?: boolean;
  hasError?: boolean;
  isLoading?: boolean;
  isTilesLoading?: boolean;
  isCollectionRail?: boolean;
  initialIndex?: number;
  tileClickHandler?: (id: string, positionIndex: number) => void;
  tilesLoadHandler?: (id: string) => void;
}

const dummyAssets = new Array(6).fill({});

const Rail: React.FC<RailProps> = props => {
  const {
    id,
    heading,
    subTitle,
    assets,
    viewName = PageType.Home,
    type = TileType.Media,
    layout = GroupLayout.RailLandscape,
    railGroup = RailGroup.MediumRail,
    aspectRatio = StandardAspectRatio._16_9,
    size = TileSize.Small,
    isSkeletonLoadingEnabled = true,
    hasNextPage = false,
    hasError = false,
    isLoading = false,
    isTilesLoading = false,
    isCollectionRail = false,
    initialIndex = 0,
    tileClickHandler,
    tilesLoadHandler,
  } = props;

  const totalItems = useCurrent(assets);
  const positionIndexRef = useRef(0);
  const { openWatchFromStartModal } = useAiringSlotModal();

  const onPaginate = usePersistCallback(
    (positionIndex: number, pageSize: number, isForward: boolean) => {
      if (id) {
        positionIndexRef.current = positionIndex;
        if (!isForward || isNil(totalItems.current) || isTilesLoading || !hasNextPage) {
          return;
        }
        if (positionIndex + pageSize * 2 >= totalItems.current?.length) {
          tilesLoadHandler?.(id);
        }
      }
    },
  );

  const onTileClick = useCallback(
    (asset, isCollection, index, assetType) => {
      if (id) {
        tileClickHandler?.(id, positionIndexRef.current);
      }

      if (assetType === 'LinearSlot') {
        openWatchFromStartModal(asset?.id);
        cardClickAnalytics(asset.id as string, asset.title as string, viewName, index + 1, {
          railTitle: heading,
          railStyle: formatLayout(layout),
          railContains: assets?.filter(x => !isNil(x)).map(x => x.id),
          railContentType: asset?.programme?.__typename,
        });
      } else if (isCollection) {
        contentCardClickedAnalytics(
          asset.id as string,
          asset.title as string,
          viewName,
          index + 1,
          heading,
          assetType,
          assets?.filter(x => !isNil(x)).map(x => x.id),
          formatLayout(layout),
        );
      } else {
        cardClickAnalytics(asset.id as string, asset.title as string, viewName, index + 1, {
          railTitle: heading,
          railStyle: formatLayout(layout),
          railContains: assets?.filter(x => !isNil(x)).map(x => x.id),
          railContentType:
            assetType === 'LinearChannel'
              ? 'Channel'
              : utils.analytics.getContentTypeByBrand(asset),
        });
      }
    },
    [assets, heading, id, layout, openWatchFromStartModal, tileClickHandler, viewName],
  );

  return (!isEmpty(assets) && !isNil(assets)) || isSkeletonLoadingEnabled ? (
    <>
      <Content>
        <Heading testid="rail-heading">{heading}</Heading>
        <div data-testid="rail-subtitle" style={{ paddingBottom: '10px' }}>
          {subTitle}
        </div>
      </Content>
      <Fade>
        <TilesList isExpandable initialPaginateIndex={initialIndex} handlePaginate={onPaginate}>
          {isLoading && isSkeletonLoadingEnabled ? (
            dummyAssets.map(() => <SkeletonTile size={size} />)
          ) : (
            <>
              {assets
                ?.filter(asset => !isNil(asset))
                .map((asset, index) => {
                  const assetType = asset.__typename;
                  let searchQuery: any = null;
                  const isCollection = assetType === ContentType.Collection || isCollectionRail;
                  const isEpisode = assetType === ContentType.Episode;
                  const isLinearSlot = assetType === ContentType.LinearSlot;
                  const bgImage = isLinearSlot
                    ? asset?.programme?.image
                    : asset?.tileImage ||
                      asset[getVideoImageType(aspectRatio)] ||
                      asset?.image ||
                      null;
                  if (isEpisode) {
                    searchQuery = {
                      playbackOrigin: viewName,
                    };
                  }
                  return type === TileType.Media ? (
                    <MediaTile
                      key={`tile-${id}-${asset.id}-${index}`}
                      image={bgImage}
                      id={!isEpisode ? asset.id : asset?.show?.id}
                      episode={isEpisode ? asset : null}
                      title={isLinearSlot ? asset?.programme?.title : asset.title}
                      defaultEpisode={asset?.defaultEpisode}
                      rating={isLinearSlot ? asset?.programme?.nzBsaRatingString : asset.rating}
                      genres={formatGenres(asset.primaryGenres)}
                      year={asset.year}
                      numberOfSeasons={asset.numberOfSeasons}
                      slot={isLinearSlot ? asset : asset.slot || null}
                      duration={asset.duration}
                      watchProgress={asset.watchProgress}
                      type={assetType}
                      aspectRatio={aspectRatio}
                      railGroup={railGroup}
                      layout={layout}
                      size={size}
                      linkSearchQuery={searchQuery}
                      onClick={() => onTileClick(asset, isCollection, index, assetType)}
                    />
                  ) : (
                    <ServiceTile
                      imageUrl={asset.imageUrl}
                      url={asset.url}
                      text={asset.text}
                      layout={layout}
                    />
                  );
                })}
              {hasNextPage && !hasError && (
                <SkeletonTile key={`tile-${id}-${assets?.length}`} size={size} />
              )}
            </>
          )}
        </TilesList>
      </Fade>
    </>
  ) : null;
};

export default Rail;
