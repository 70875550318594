import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import qs from 'query-string';

import { LinearChannel } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { LiveVideoPlayer } from '@/Components/VideoPlayer';
import useHistoryBack from '@/Hooks/useHistoryBack';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import pageViewedAnalytics from '@/Analytics/Page';
import RoutePath from '@/Routes/RoutePath';
import useQueryString from '@/Hooks/useQueryString';
import { PLAYER_REFERRER, PLAYER_MODE, PLAYER_REFERRER_QUERY_KEY } from '@/Utils/PageLocation';

const WATCH_FROM_START_QUERY_KEY = 'watchFromStart';
const WFS_SOURCE_START_TIME_QUERY_KEY = 'startPosition';
const PLAYER_MODE_QUERY_KEY = 'playerMode';

const LiveTV: React.FC = () => {
  const { channelId = '' } = useParams<any>();

  const history = useHistory();
  const goBack = useHistoryBack();
  const onClose = useCallback(() => {
    goBack();
    return false;
  }, [goBack]);

  const [pageTitle, setPageTitle] = useState('');
  usePageHeader({
    title: pageTitle,
  });

  useEffect(() => {
    pageViewedAnalytics('Live TV');
  }, []);

  const query = useQueryString();
  const playerReferrer = query[PLAYER_REFERRER_QUERY_KEY] as string | undefined;
  const playerMode = query[PLAYER_MODE_QUERY_KEY] as string | undefined;
  const watchFromStart = query[WATCH_FROM_START_QUERY_KEY] as string | undefined;
  const startPosition = query[WFS_SOURCE_START_TIME_QUERY_KEY] as string | undefined;

  const handleVideoLoad = useCallback(
    (channel: LinearChannel, isFirstLoad: boolean) => {
      // if it is linear slot then do nothing
      if (playerMode === PLAYER_MODE.linearSlot) {
        return;
      }

      setPageTitle(channel.title);

      const livePageUrl = qs.stringifyUrl({
        url: generatePath(RoutePath.watchTv, {
          channelId: channel.id,
        }),
        query:
          // If the player was triggered from some origin page, we will forward the page name info
          // Otherwise, if the player was triggered for something internal
          // eg. Selecting a different channel on the channel drawer
          // Then we set 'Live TV' as referrer, that is a requirement for analytics
          { playerReferrer: isFirstLoad ? playerReferrer : PLAYER_REFERRER.LIVE_TV },
      });
      history.replace(livePageUrl);
    },
    [history, playerMode, playerReferrer],
  );

  return (
    <LiveVideoPlayer
      channelId={channelId}
      fullWindow
      autoPlay
      exitFullWindowOnPlayEnd
      playerReferrer={playerReferrer}
      onExitFullWindow={onClose}
      onVideoMetaLoad={handleVideoLoad}
      playerMode={playerMode}
      watchFromStart={watchFromStart === 'true'}
      startPosition={startPosition}
    />
  );
};

export default withTransaction('LiveTV', 'component')(LiveTV);
