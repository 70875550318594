import React from 'react';
import { useToggle, useLockBodyScroll } from 'react-use';
import { NavLink, Link, generatePath } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import Preloader from '@/Layouts/containers/Preloader';
import { Logo, Menu, Close } from 'Assets/all';
import useAuth from '@/Hooks/useAuth';
import { getLoginUrl } from '@/Utils/redirectToLogin';
import Content from '@/Layouts/containers/Content';
import { useGlobalSlotView } from '@/Pages/TVGuide/SlotDetails/useSlotDetails';
import useRootSections from '@/Hooks/useRootSections';
import SearchInput from './SearchInput';

import styles from './styles.module.scss';

import ProfileDropDown from '../DropDown/ProfileDropDown';

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [, setGlobalSlotView] = useGlobalSlotView();
  const navigationMenuItems = useRootSections();

  const [showMobileNav, toggleShowMobileNav] = useToggle(false);

  const loginUrl = getLoginUrl();
  const registerUrl = getLoginUrl(true);

  useLockBodyScroll(showMobileNav);

  const handleMobileNavClick = () => {
    toggleShowMobileNav();
  };

  const handleNavLinkClick = () => {
    toggleShowMobileNav(false);
    setGlobalSlotView(null);
  };
  if (navigationMenuItems?.loading) {
    return <Preloader isLoading={navigationMenuItems?.loading} />;
  }
  return (
    <header className={styles.appHeader}>
      <Content className={styles.content}>
        <span className={styles.appLogo}>
          <Link to="/" data-testid="header-logo">
            <Logo className={styles.appLogoIcon} />
          </Link>
        </span>
        <nav
          className={classnames(styles.nav, { [styles.navMobileShow]: showMobileNav })}
          data-testid="header-nav"
        >
          {navigationMenuItems?.filterData?.map((item, index) => (
            <NavLink
              key={index}
              exact
              to={{
                pathname: generatePath(item?.path),
                state: { sectionId: item?.rootSectionId, pageName: item?.label },
              }}
              className={styles.navLink}
              activeClassName={styles.active}
              data-testid={`header-home-${index}`} // unique data-testid for each item
              onClick={handleNavLinkClick}
            >
              {item.label}
            </NavLink>
          ))}
          {!isAuthenticated && (
            <>
              <NavLink
                to={registerUrl}
                onClick={handleNavLinkClick}
                className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                data-testid="header-mobile-register"
              >
                REGISTER
              </NavLink>
              <NavLink
                to={loginUrl}
                onClick={handleNavLinkClick}
                className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                data-testid="header-mobile-login"
              >
                SIGN IN
              </NavLink>
            </>
          )}
        </nav>
        <SearchInput className={styles.searchPanel} />
        <ProfileDropDown className={classnames(styles.mobileProfileLink)} />
        {isAuthenticated ? (
          <span
            className={classnames(styles.navLink, styles.authBtn, styles.profileLink)}
            data-testid="header-profile"
          >
            <ProfileDropDown className={styles.profileDropDown} />
          </span>
        ) : (
          <>
            <NavLink
              to={registerUrl}
              onClick={handleNavLinkClick}
              className={classnames(styles.navLink, styles.authBtn)}
              data-testid="header-register"
            >
              REGISTER
            </NavLink>
            <NavLink
              to={loginUrl}
              onClick={handleNavLinkClick}
              className={classnames(styles.navLink, styles.authBtn)}
              data-testid="header-login"
            >
              SIGN IN
            </NavLink>
          </>
        )}
        <Button
          type="link"
          className={classnames(styles.headerBtn, styles.mobileNavBtn, styles.mobileOnlyBtn)}
          onClick={handleMobileNavClick}
          data-testid="header-mobile-nav-btn"
          name="Header mobile nav button"
        >
          <Icon component={showMobileNav ? Close : Menu} />
        </Button>
      </Content>
    </header>
  );
};

export default Header;
