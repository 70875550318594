import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

import { Season } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';

import { selectors, actions, utils } from '@/Store';
import { Video } from '@/Analytics';
import { VideoEventType, VideoContentEventProps } from '@/Analytics/Video';
import sessionId from '@/Utils/SessionId';
import usePersistCallback from '@/Hooks/usePersistCallback';

import { convertDurationToSeconds } from '@/Utils/DurationFormatter';
import useIsLoaded from '@/Hooks/useIsLoaded';
import useParentalForbidden from '../ParentalControlPanel/useParentalForbidden';
import { DRM_TYPE } from '../DRM';

const useVodData = (
  brandId: string,
  episodeId?: string,
  isShow?: boolean,
  isParentalRestrictionResolved?: boolean,
  playbackOrigin?: string,
) => {
  const dispatch = useDispatch();
  const brand = useSelector(selectors.titles.getBrand)(brandId);
  const brandError = useSelector(selectors.titles.brandError);
  const isBrandLoading = useSelector(selectors.titles.brandIsLoading);
  const isBrandLoaded = useIsLoaded(isBrandLoading);
  const getShowEpisode = useSelector(selectors.titles.getShowEpisode);
  const episode = episodeId ? getShowEpisode(brandId, episodeId) : null;

  const selectedSeason = useSelector(selectors.titles.getSelectedSeason)(brandId);
  const seasons = useSelector(selectors.titles.getSeasons)(brandId);

  const playbackPosition =
    brand?.__typename === BrandType.Movie ? brand.watchProgress : episode?.watchProgress;

  const mediaAsset = brand?.__typename === BrandType.Movie ? brand.asset : episode?.asset;
  const mediaAssetId = mediaAsset?.id;

  const playbackMeta = useSelector(selectors.playback.getPlaybackMeta)(mediaAssetId || '');
  const playbackMetaError = useSelector(selectors.playback.playbackMetaError);
  const isPlaybackError = brandError || playbackMetaError;

  const isConcurrencyLimitReached = useSelector(selectors.playback.isConcurrentPlaybackError)(
    mediaAssetId || '',
  );

  const previousEpisode = usePrevious(episode);

  // Parental forbidden flag
  const isParentalForbidden = useParentalForbidden(mediaAsset, isParentalRestrictionResolved);

  const captureVideoContentEvent = useCallback(
    (eventType: VideoEventType, position: number, origin?: string) => {
      if (playbackMeta && brand) {
        const eventProps: VideoContentEventProps = {
          title: utils.analytics.getSegmentVodTitle(brand, episode),
          genre: utils.analytics.getSegmentBrandGenres(brand),
          type: utils.analytics.getContentTypeByBrand(brand),
          origin: origin || playbackOrigin || '',
          platform: 'Web',
          playback: 'VOD',
          position: Math.floor(position),
          duration: convertDurationToSeconds(mediaAsset?.duration),
          channel: utils.analytics.getSegmentControllingChannel(brand),
          content_id: isShow ? episode?.id : brand?.id,
          season: utils.analytics.getVodSeasonNumber(selectedSeason),
          episode: utils.analytics.getVodEpisodeNumber(episode, utils.title.isSport(brand)),
        };
        Video.recordContentEvent(eventType, eventProps);
      }
    },
    [brand, isShow, episode, playbackMeta, playbackOrigin, selectedSeason, mediaAsset],
  );

  const selectSeason = usePersistCallback((season: Season) => {
    dispatch(actions.titles.selectSeason(brandId, season.id));
  });

  const clearVodPlayback = usePersistCallback((assetId, shouldStop = true) => {
    if (!assetId) {
      return;
    }
    if (shouldStop) {
      dispatch(actions.playback.stopPlayback(assetId, false, sessionId));
    }
    dispatch(actions.playback.removePlaybackMeta(assetId));
  });

  const stopPreviousEpisodePlayback = usePersistCallback(() => {
    const previousAssertId = previousEpisode?.asset?.id;
    clearVodPlayback(previousAssertId);
  });

  const onPlaybackRetry = usePersistCallback(() => {
    if (mediaAssetId) {
      dispatch(actions.playback.fetchPlaybackMeta(mediaAssetId, false, DRM_TYPE, sessionId));
    }
  });

  useEffect(() => {
    if (!brandId || isBrandLoaded) {
      return;
    }
    dispatch(actions.titles.fetchBrand({ brandId, isShow }));
  }, [dispatch, brandId, isBrandLoaded, isShow]);

  useEffect(() => {
    if (isParentalForbidden === false && mediaAssetId) {
      dispatch(actions.playback.fetchPlaybackMeta(mediaAssetId, false, DRM_TYPE, sessionId));
    }
  }, [dispatch, isParentalForbidden, mediaAssetId, stopPreviousEpisodePlayback]);

  return {
    brand,
    episode,
    seasons,
    selectedSeason,
    mediaAssetId,
    playbackMeta,
    isConcurrencyLimitReached,
    isPlaybackError,
    playbackPosition,
    isParentalForbidden,
    clearVodPlayback,
    onPlaybackRetry,
    selectSeason,
    captureVideoContentEvent,
  };
};

export default useVodData;
