/* eslint-disable no-else-return */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '@skytvnz/sky-app-store';
import Preloader from '@/Layouts/containers/Preloader';
import SlotProgress from '@/Components/SlotProgress';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';
import RoutePath from '@/Routes/RoutePath';
import dayjs from 'dayjs';
import history from '@/Routes/History';
import { actions } from '@/Store';
import { PLAYER_MODE, PLAYER_REFERRER } from '@/Utils/PageLocation';
import SimpleModal, { ButtonStyle } from '@/Components/Modal/SimpleModal';
import styles from './styles.module.scss';

export interface ModalProps {
  onClose?: any;
}

const AIRING_SLOT_WATCH_FROM_START = {
  TITLE_EXPIRED: 'This content might have ended',
  SUB_TITLE: 'Watch Live or Watch from Start?',
  SUB_TITLE_EXPIRED: 'Watch from Start?',
  FIRST_BUTTON_LABEL: 'Watch Live',
  FIRST_BUTTON_LABEL_EXPIRED: 'Cancel',
  SECOND_BUTTON_LABEL: 'Watch from Start',
  FIRST_BUTTON_ICON: 'icon-watch-tv-white',
  SECOND_BUTTON_ICON: 'icon-catchup',
};

const AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED = {
  TITLE: 'Watch from Start is not available for this content',
  SUB_TITLE: 'Watch Live?',
  FIRST_BUTTON_LABEL: 'Cancel',
  SECOND_BUTTON_LABEL: 'Watch Live',
  SECOND_BUTTON_ICON: 'icon-watch-tv-white',
};

const AiringSlotModalDetails: React.FC<ModalProps> = modalProps => {
  const { onClose } = modalProps;
  const dispatch = useDispatch();
  const selectedSlot = useSelector(selectors.channels.selectedSlot);
  const now = dayjs();
  const isSlotExpired = now.isAfter(dayjs(selectedSlot?.end));
  const isAllowedPlayFromStart = selectedSlot?.allowPlayFromStart;
  const initStart = selectedSlot?.start;

  const prepareLinkAndRedirect = useCallback(
    async (wfs: boolean) => {
      if (selectedSlot?.channel?.id) {
        const link = qs.stringifyUrl({
          url: generatePath(RoutePath.watchTv, {
            channelId: selectedSlot?.channel?.id,
          }),
          query: {
            playerReferrer: PLAYER_REFERRER.LINEAR_SLOT,
            playerMode: PLAYER_MODE.linearSlot,
            watchFromStart: wfs,
            startPosition: selectedSlot?.start,
          },
        });

        const offset = isSlotExpired ? dayjs().diff(initStart, 's') : 0;

        await dispatch(
          actions.channels.fetchSelectedChannelSlot(selectedSlot?.channel.id, offset, true),
        );
        history.push(link);
      }
    },
    [dispatch, initStart, isSlotExpired, selectedSlot],
  );

  const watchFromStart = useCallback(() => {
    prepareLinkAndRedirect(true);
  }, [prepareLinkAndRedirect]);

  const watchLive = useCallback(() => {
    prepareLinkAndRedirect(false);
  }, [prepareLinkAndRedirect]);

  const modalAttributes = useMemo(() => {
    if (isAllowedPlayFromStart && !isSlotExpired) {
      return {
        title: selectedSlot?.programme?.title,
        subtitle: AIRING_SLOT_WATCH_FROM_START.SUB_TITLE,
        firstBtnLabel: AIRING_SLOT_WATCH_FROM_START.FIRST_BUTTON_LABEL,
        firstBtnIcon: AIRING_SLOT_WATCH_FROM_START.FIRST_BUTTON_ICON,
        firstBtnPress: watchLive,
        secondBtnLabel: AIRING_SLOT_WATCH_FROM_START.SECOND_BUTTON_LABEL,
        secondBtnIcon: AIRING_SLOT_WATCH_FROM_START.SECOND_BUTTON_ICON,
        secondBtnPress: watchFromStart,
      };
    } else if (isAllowedPlayFromStart && isSlotExpired) {
      return {
        title: AIRING_SLOT_WATCH_FROM_START.TITLE_EXPIRED,
        subtitle: AIRING_SLOT_WATCH_FROM_START.SUB_TITLE_EXPIRED,
        firstBtnLabel: AIRING_SLOT_WATCH_FROM_START.FIRST_BUTTON_LABEL_EXPIRED,
        firstBtnPress: onClose,
        secondBtnLabel: AIRING_SLOT_WATCH_FROM_START.SECOND_BUTTON_LABEL,
        secondBtnIcon: AIRING_SLOT_WATCH_FROM_START.SECOND_BUTTON_ICON,
        secondBtnPress: watchFromStart,
      };
    } else {
      return {
        title: AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED.TITLE,
        subtitle: AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED.SUB_TITLE,
        firstBtnLabel: AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED.FIRST_BUTTON_LABEL,
        firstBtnPress: onClose,
        secondBtnLabel: AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED.SECOND_BUTTON_LABEL,
        secondBtnIcon: AIRING_SLOT_WATCH_FROM_START_NOT_ALLOWED.SECOND_BUTTON_ICON,
        secondBtnPress: watchLive,
      };
    }
  }, [isAllowedPlayFromStart, isSlotExpired, onClose, selectedSlot, watchFromStart, watchLive]);

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const content = useMemo(() => {
    return (
      <>
        <h3 className={styles.modalSubtitle} data-testid="modal-title">
          {modalAttributes.subtitle}
        </h3>

        <SlotProgress
          slotStart={selectedSlot?.start}
          slotEnd={selectedSlot?.end}
          alwaysVisible
          progressBarWidth={styles.progressBarWidth}
        />
      </>
    );
  }, [modalAttributes.subtitle, selectedSlot]);

  return (
    <Preloader isLoading={!selectedSlot}>
      <SimpleModal
        title={modalAttributes.title}
        content={content}
        okText={modalAttributes.firstBtnLabel}
        onOk={modalAttributes.firstBtnPress}
        cancelText={modalAttributes.secondBtnLabel}
        onCancel={modalAttributes.secondBtnPress}
        cancelable
        onClose={onClose}
        customStyle={{
          okButtonStyle: ButtonStyle.DEFAULT,
          cancelButtonStyle: ButtonStyle.PRIMARY,
          modalContent: styles.modalContent,
          modalTitle: styles.modalTitle,
          modal: styles.modal,
        }}
      />
    </Preloader>
  );
};

export default AiringSlotModalDetails;
