import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import Preloader from '@/Layouts/containers/Preloader';
import Login from '@/Pages/Login';
import LinkAccountCallback from '@/Pages/LinkAccountCallback';
import useRootSections from '@/Hooks/useRootSections';
import About from '@/Pages/About';
import Redirect from '@/Pages/Redirect';
import Search from '@/Pages/Search';
import LiveTV from '@/Pages/LiveTV';
import Collection from '@/Pages/Collection';
import ShowDetail from '@/Pages/Detail/ShowDetail';
import MovieDetail from '@/Pages/Detail/MovieDetail';
import Browse, { BrowseCollection } from '@/Pages/Browse';
import DeviceLogin from '@/Pages/DeviceLogin';
import NotFound from '@/Pages/NotFound';
import DeviceLogout from '@/Pages/DeviceLogout';
import ManageProfiles from '@/Pages/CustomerProfile/ManageProfiles';
import Home from '@/Pages/Home';
import TVGuide from '@/Pages/TVGuide';
import Watch from '@/Pages/Watch';
import PrivateRoute from './PrivateRoute';
import RoutePath from './RoutePath';

const Routes: React.FC = () => {
  const navigationMenuItems = useRootSections();
  // Map of component names to their lazy imports
  const componentMap = {
    WATCH_TV: LiveTV,
    HOME: Home,
    TV_GUIDE: TVGuide,
    BROWSE: Browse,
  };
  if (navigationMenuItems?.loading) {
    return <Preloader isLoading={navigationMenuItems?.loading} />;
  }
  return (
    <Suspense fallback={<Preloader isLoading={navigationMenuItems?.loading} />}>
      <Switch>
        {/* Util pages */}
        <ApmRoute exact path={RoutePath.register} component={Login} />
        <ApmRoute exact path={RoutePath.login} component={Login} />
        <PrivateRoute path={RoutePath.watchTv} component={LiveTV} />
        <PrivateRoute path={RoutePath.watch} component={Watch} />
        <PrivateRoute path={RoutePath.linkAccountCallback} component={LinkAccountCallback} />
        <Route exact path={RoutePath.about} component={About} />
        <Route exact path={RoutePath.redirect} component={Redirect} />
        <Route path={RoutePath.show} component={ShowDetail} />
        <Route path={RoutePath.movie} component={MovieDetail} />
        <Route path={RoutePath.collection} component={Collection} />
        <Route path={RoutePath.search} component={Search} />
        <Route path={RoutePath.browseCollection} component={BrowseCollection} />
        <Route path={RoutePath.deviceLogin} component={DeviceLogin} />
        <Route path={RoutePath.deviceLogout} component={DeviceLogout} />
        <Route exact path={RoutePath.profile} component={ManageProfiles} />
        {/* Fallback route to use if the API call fails */}
        <Route exact path={RoutePath.home} component={Home} />

        {navigationMenuItems?.filterData?.map((item, index) => {
          const Component = componentMap[item?.predefinedId]; // Match API component name with local component
          return <Route key={index} exact path={item.path} component={Component || NotFound} />;
        })}
        <ApmRoute path="*" component={NotFound} />
        {/* Must be the last one */}

        {/* Dev Demo pages
                <PrivateRoute exact path={RoutePath.logout} component={Logout} />
                <PrivateRoute path="/demo/layout" component={LayoutDemo} />
                <PrivateRoute path="/demo/watch" component={WatchDemo} />
            */}
      </Switch>
    </Suspense>
  );
};

export default Routes;
